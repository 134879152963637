<template>
    <div class="main-container-Fifth-filter">
        <div class="title-container-Fifth-filter">
            <p> Configuración de la cartera </p>
        </div>
        <div class="content-container-Fifth-filter">
            <div class="account_value-container">
                <DigitOutlineInputFiltersFifth
                    :id="'filters-Fifth-account-value'"
                    :label="'Capital Real €'"
                    @inputChanged="saveAccountValue"
                    :placeholder="placeholder_account"
                    class="material-input"
                />
            </div>
            <div class="risk-container">
                <DigitOutlineInputFiltersFifth
                    :id="'filters-Fifth-risk'"
                    :label="'Riesgo %'"
                    @inputChanged="saveRisk"
                    :placeholder="placeholder_risk"
                    class="material-input"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
    import DigitOutlineInputFiltersFifth from '@/components/helpers/newMaterial/DigitOutlineInputFiltersFifth.vue';
    export default {
        name: 'Fifth',
        components: {
            DigitOutlineInputFiltersFifth
        },
        data: () => ({
            finder: "",
            placeholder_account: "",
            placeholder_risk: "",
        }),
        mounted() {

        },
        created(){
            this.finder = this._g_FinderFilters();
            this.placeholder_account = this.finder["account_value"];
            this.placeholder_risk = this.finder["risk"];
        },
        watch: {
        },
        computed: {  
        },
        methods: {
            ...mapGetters(['_g_FinderFilters']),
            ...mapMutations(['setFinderFilters']),

            _updateLastSearch2: function(){
                this.setFinderFilters(this.finder);
                this.$emit("updateLastSearch", this.finder);
            },  

            saveAccountValue: function(value) {
                const res = value == "" ? 0 : value;
                this.finder["account_value"] = parseInt(res);
                this._updateLastSearch2();
            },

            saveRisk: function(value) {
                const res = value == "" ? 0 : value;
                this.finder["risk"] = parseInt(res);
                this._updateLastSearch2();
            },
        }
    }
</script>

<style scoped>
    .content-container-Fifth-filter{
        display: flex;
        gap: 10px; 
        margin-top: 15px;
    }

    .title-container-Fifth-filter p{
        font-weight: 600;
        font-size: 18px;
    }

    .account_value-container,
    .risk-container{
        width: 150px;
    }
</style>
