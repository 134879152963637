import { mapGetters, mapMutations } from "vuex";
import APICaller from "./APICaller";
export default {
    computed:{
      ...mapGetters(['_g_SelectedTicker','is_mobile_device', '_g_is_loading_stocks', '_g_portfolio_id_selected']),
    },
    methods: {
      ...mapMutations(['setStocks','setSelectedTicker', 'setIsLoadingStocks']),

      tickerChangerWatcher: function() {
        let last_ticker_search_by_user = '';
        var self = this;

        setInterval(function() {
          var ticker_search_by_user = window.sessionStorage.getItem('ticker_search_by_user');
          if (ticker_search_by_user && ticker_search_by_user != '' && last_ticker_search_by_user != ticker_search_by_user) {
            last_ticker_search_by_user = ticker_search_by_user;
            self.$router.push('/app/graficador-dinamico/ticker/' + ticker_search_by_user);
          }
        }, 1000)
      },

      getStocks: function(callback){
        if (this._g_portfolio_id_selected == undefined || this._g_portfolio_id_selected == null) {
          return [];
        }

        this.setIsLoadingStocks(true);
        this.stocks = null;
        if(this.$route.params.param1 == "platinum" && !this.filters){
          this._getPlatinumValues();
        }else if(this.$route.params.param1 == "last-ipos"){
          this._getStocksIPO();
        }else if(this.$route.params.param1 == "best-bullish-values"){
          var bullish_timing = this.$route.params.param2
          this._getStocksBestBullish(bullish_timing);
        }else if(this.$route.params.param1 == "best-sectors"){
          var sector_timing = this.$route.params.param2
          this._getStocksBestSectors(sector_timing);
        }else if(this.$route.params.param1 == "hot-values"){
          var hot_value_timing = this.$route.params.param2;
          this._getStocksHotValues(hot_value_timing);
        }else if(this.$route.params.param1 == "compatibility"){
          this._getStocksCompatibility();
        }
        else if (this.$route.params.param1 == "exchange") {
          var exchange_index = this.$route.params.param2;
          this._getStocksExchange(exchange_index);
        }
        else if (this.$route.params.param1 == "sector") {
          var sector = this.$route.params.param2;
          this._getStocksSector(sector);
        }
        else if (this.$route.params.param1 == 'ticker') {
          var ticker = this.$route.params.param2;
          this._getStockByTickerName(ticker);
        }
        else if (this.$route.params.param1 == "favorites") {
          var folder = this.$route.params.param2;
          this._getStocksFavorites(folder);
        }
        else if(this.$route.params.param1 == "waitings"){
          let idc = this.$route.params.param2;
          this._getWaitingValues(idc);
        }else if(this.$route.params.param1 == "open-positions"){
          let idc = this.$route.params.param2;
          this._getOpenPositionsValues(idc);
        }else if(this.$route.params.param1 == "all-values"){
          let idc = this.$route.params.param2;
          this._getAllValues(idc);
        }else if(this.$route.params.param1 == "twin-souls"){
          let index = this.$route.params.param2;
          this._getTwinValues(index);
        }
        else{
          this._getDefaultStocks();
        }   

        var finish_interval = setInterval(() => {
          if (this.stocks != undefined  && this.stocks != null && this.stocks.length != 0) {
            this.setStocks(this.stocks);
            this.setIsLoadingStocks(false);
            clearInterval(finish_interval);
          }
        }, 200);

        if (callback != undefined && callback != null) {
          var callback_interval = setInterval(() => {
            if (this.stocks != undefined  && this.stocks != null && this.stocks.length != 0) {
              callback();
              this.setIsLoadingStocks(false);
              clearInterval(callback_interval);
            }
          }, 200);
        }
      },

      _getDefaultStocks: function () {
        let success = response => {
          this.stocks = response.data;
          if(this.stocks.length != 0){
            this.setSelectedActiveTicker(this.stocks[0]);
          }

          this._setStocks();
          this._url_stock();
        }
  
        let url = "/api/v1/stock-finder/stocks";
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('007', 'Error recuperando valores.');
        const apiCallConfig = {loadingOverlay: false};

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      },
  
      _getStocksCompatibility: function(){
        let success = response =>{
          this.stocks = response.data;
          var queryString = window.location.search;
          var urlParams = new URLSearchParams(queryString);
          var anuncioParam = urlParams.get('active_ticker');
          this.setSelectedActiveTicker(this.stocks[0]);
          
          for(var i=0; i < this.stocks.length; i++){
            if(this.stocks[i].ticker == anuncioParam){
              this.setSelectedActiveTicker(this.stocks[i]);
            }
          }
        } 
  
        var url = '';
  
        if(this.$route.query.currency != undefined && this.$route.query.currency != ''){
          url = '/api/v1/stock-finder/stocks/compatibility?currency=' + this.$route.query.currency;
        }
        else{
          url = '/api/v1/stock-finder/stocks/compatibility';
        }
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('008', 'Error recuperando valores.');

        this._getAPICall(url, successHandler, failureHandler)
      },
  
      _getStocksFavorites: function(folder) {
        let success = response => {
          this.stocks = response.data;
  
          this.setSelectedActiveTicker(this.stocks[0]);
        }
  
        let url = '/api/v1/stock-finder/stocks/favoritos/' + folder;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('009', 'Error recuperando valores favoritos.');
        const apiCallConfig = {timeoutMs: 0};

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig)
      },

      _getStocksDescriptionSearch: function(search_term) {
        let success = response => {
          this.stocks = response.data;
          if(this.stocks.length != 0){
            this.setSelectedActiveTicker(this.stocks[0]);
          }

          this._setStocks();
          this._url_stock();
        }
  
        let url = '/api/v1/stock-finder/stocks/busqueda-descripcion';

        let params = new URLSearchParams();
        params.append('search_term', search_term);

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('233', 'Error en la búsqueda.');

        this._postAPICall(url, params, successHandler, failureHandler);
      },
  
      _getStocksIPO() {
        let success = response => {
          this.stocks = response.data;
  
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }
  
        let url = '/api/v1/stock-finder/last-ipos'
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('010', 'Error recuperando valores.');

        this._getAPICall(url, successHandler, failureHandler)
      },
  
      _getStocksBestBullish(bullish_timing) {
        let success = response =>{
          this.stocks = response.data;
  
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }
  
        let url = '/api/v1/stock-finder/best-bullish-values/' + bullish_timing;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('011', 'Error recuperando valores.');
        const apiCallConfig = {timeoutMs: 0};

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig)
      },
      
      _getStocksBestSectors(sector_timing) {
        let success = response => {
          this.stocks = response.data;
  
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }
  
        let url = '/api/v1/stock-finder/best-sectors/' + sector_timing;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('012', 'Error recuperando valores.');

        this._getAPICall(url, successHandler, failureHandler)
      },
  
      _getStocksHotValues(hot_value_timing) {
        let success = response => {
          this.stocks = response.data;
  
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }
  
        let url = '/api/v1/stock-finder/hot-values/' + hot_value_timing
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('013', 'Error recuperando valores.');

        this._getAPICall(url, successHandler, failureHandler)
      },

      _url_stock() {
        if(this._g_SelectedTicker.chart != undefined){
          if (this._g_SelectedTicker.chart.url != undefined) {
              //return  this._g_SelectedTicker.ticker; descomentamos estas dos lineas y comentamos la de abajo
              this.url_stock = process.env.VUE_APP_API + '/stockchart?t=23&s=' + this._g_SelectedTicker.chart.url;
          }else{
              this.url_stock = process.env.VUE_APP_API + '/stockchart?t=23&s=aapl';
              //return 'AAPL'; descomentamos esta linea y comentamos la de arriba
          }
        }
      },
  
      _getStocksExchange(exchange_index) {
        
        let success = response => {
          this.stocks = response.data;
          
  
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }

        let url = '/api/v1/stock-finder/stocks/index/' + exchange_index;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('014', 'Error recuperando valores.');

        this._getAPICall(url, successHandler, failureHandler);
      },
  
      _getStocksSector(sector) {
        let success = response => {
          this.stocks = response.data;
  
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }
  
        let params = new URLSearchParams();
        params.append('sector', sector);
  
        let url = '/api/v1/stock-finder/stocks/sector';
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('234', 'Error recuperando stocks.');

        this._postAPICall(url, params, successHandler, failureHandler);
      },
  
      _getStockByTickerName(ticker) {
        let success = response => {
          this.stocks = response.data;
          var that = this;
          
  
          if (this.stocks.length == 0) {
            let success2 = response => {
              that.stocks = [];
              that.stocks[0] = response.data;
              that.setSelectedActiveTicker(that.stocks[0]);
              that._g_SelectedTicker.chart = {
                image: '',
                url: '/stockchart?&s=' + ticker,
              };
              that._g_SelectedTicker.isEureker = false;
            }
            let url2 = '/api/v1/stock-finder/stocks/symbol-without-entry/' + ticker;
  
            const successHandler = new APICaller.SuccessHandler(success2);
            const failureHandler = new APICaller.FailureHandler('015', 'Error recuperando valores no eurekers.');

            this._getAPICall(url2, successHandler, failureHandler);
          }
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }
  
        let url = '/api/v1/stock-finder/stocks/symbol/' + ticker;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('016', 'Error recuperando valores.');

        this._getAPICall(url, successHandler, failureHandler);
      },
  
  
      _getPlatinumValues: function(){
        let success = (response) => {
          if(response.data.length == 0){
            this.$vueOnToast.pop('info', "Platino", "Porfavor cambie a Platino para utilizar esta funcionalidad");
            this.setMenuSelectionOut(4);
          }else{
            this.stocks = response.data;                
            this.setSelectedActiveTicker(this.stocks[0]);
            this._setStocks();
            this._url_stock();
          }              
        };
  
        let url = "/api/v1/stock-finder/stocks/platinum";
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('017', 'Error recuperando valores platino.');

        this._getAPICall(url, successHandler, failureHandler);
      },
      _getWaitingValues: function(idc) {
        let that = this;
        let success = response => {
          let tickers = response.data;
          let result = [];
          tickers.forEach(element =>{
            result.push(element.ticker)
          })
          that._getStocksFromArray(result)

        }
         
        let url = '/api/v1/portfolio/waitings/' + idc;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('161.2', 'Error recuperando valores en espera.');
        const apiCallConfig = {loadingOverlay: false};

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      
      },
      _getOpenPositionsValues: function(idc) {
        let success = response => {
          let tickers = response.data;
          let result = [];
          tickers.forEach(element =>{
            result.push(element.ticker)
          })
          this._getStocksFromArray(result)

        }
         
        let url = '/api/v1/portfolio/open_positions/' + idc;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('161.3', 'Error recuperando valores en espera.');
        const apiCallConfig = {loadingOverlay: false};

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      
      },
      _getAllValues: function(idc){
        let success = response => {
          let tickers = response.data;
          let result = [];
          tickers.forEach(element =>{
            result.push(element.ticker)
          })
          this._getStocksFromArray(result)

        }
         
        let url = '/api/v1/portfolio/all-tickers/' + idc;
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('161.3', 'Error recuperando valores en espera.');
        const apiCallConfig = {loadingOverlay: false};

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);

      },

      _getTwinValues: function(index){
        let success = response => {
          let all_twins = response.data;
          let pre_stocks = all_twins[index]["stocks"];
          let result = pre_stocks.map(obj => obj.name);
          this._getStocksFromArray(result)
        }
  
        let url = '/api/v1/widgets/get-twin-souls';
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('319', 'Error recuperando stocks.');

        this._getAPICall(url, successHandler, failureHandler);
      },

      _getStocksFromArray: function(result){
        let params = new URLSearchParams();
        params.append('array_tickers', result);
        let success = response => {
          this.stocks = response.data;  
          this.setSelectedActiveTicker(this.stocks[0]);
          this._setStocks();
          this._url_stock();
        }

        let url = "/api/v1/stock-finder/stocks/symbols/array"

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('313', 'Error recuperando valores por array.');
        const apiCallConfig = {timeoutMs: 0};

        this._postAPICall(url, params, successHandler, failureHandler, apiCallConfig);
      },
      
      setSelectedActiveTicker(ticker) {
        if(this.is_mobile_device){
          if(this._g_SelectedTicker != {} && this._g_SelectedTicker != undefined){
            //hace bucle
            let flag = false;
            this.stocks.forEach(element => {
              if(element.ticker == this._g_SelectedTicker.ticker){
                flag = true;
                this.setSelectedTicker(this._g_SelectedTicker);
              }
            })
            if(!flag){
              this.setSelectedTicker(ticker);
            }
        
          }else{
            this.setSelectedTicker(ticker);
          }
        }
        else{
          this.setSelectedTicker(ticker);  
        }
      },

      _setStocks(){
        this.setStocks(this.stocks)
      }
    }
}