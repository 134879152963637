<template>
    <div id="fourth" class="filter-section">
        <!-- Use v-if="finder" to prevent rendering before finder data is ready -->
        <div v-if="finder" class="fourth-group">
            
            <!-- Divisas Section -->
            <div class="filter-group">
                <h4 class="filter-group-title">Divisas</h4>
                <!-- Check for active role -->
                <div v-if="_g_UserFeatures.has_active_role">
                     <div class="selected-items-display">
                        <template v-if="divisas.length === 0">
                            <span class="no-selection">Ninguna seleccionada</span>
                        </template>
                        <template v-else>
                            <span class="selected-tag" v-for="item in divisas" :key="`divisa-${item}`">{{ item }}</span>
                        </template>
                    </div>
                    <button 
                        @click="showCurrencyModal = true" 
                        class="action-btn secondary-btn select-btn" 
                        :disabled="!_g_UserFeatures.permissions.currency_filter"
                    >
                        <i class="material-icons">edit</i>
                        <span>Seleccionar Divisas</span>
                    </button>
                    <!-- Modal is INSIDE the v-if -->
                    <MultiSelectCheckboxModal
                        :visible="showCurrencyModal"
                        title="Seleccionar Divisas"
                        :options="currencyOptions" 
                        :selectedValues="finder.currency || []" 
                        @close="showCurrencyModal = false"
                        @apply-selection="handleCurrencySelection"
                    />
                </div>
                 <!-- v-else is now adjacent -->
                 <div v-else>
                    <FilterNoPermission :link="buy_link" />
                 </div>
            </div>

            <!-- País Section -->
             <div class="filter-group">
                <h4 class="filter-group-title">País</h4>
                 <!-- Check for active role -->
                 <div v-if="_g_UserFeatures.has_active_role">
                      <div class="selected-items-display">
                        <template v-if="paises.length === 0">
                            <span class="no-selection">Ninguno seleccionado</span>
                        </template>
                        <template v-else>
                            <span class="selected-tag" v-for="item in paises" :key="`pais-${item}`">{{ item }}</span>
                        </template>
                    </div>
                     <button 
                        @click="showCountryModal = true" 
                        class="action-btn secondary-btn select-btn"
                        :disabled="!_g_UserFeatures.permissions.country_filter"
                     >
                         <i class="material-icons">edit</i>
                         <span>Seleccionar Países</span>
                    </button>
                     <!-- Modal is INSIDE the v-if -->
                    <MultiSelectCheckboxModal
                        :visible="showCountryModal"
                        title="Seleccionar Países"
                        :options="countryOptions"
                        :selectedValues="finder.country || []"
                        @close="showCountryModal = false"
                        @apply-selection="handleCountrySelection"
                    />
                 </div>
                 <!-- v-else is now adjacent -->
                 <div v-else>
                     <FilterNoPermission :link="buy_link" />
                 </div>
            </div>

            <!-- Sector | Industria Section -->
             <div class="filter-group sector-group">
                 <h4 class="filter-group-title">Sector | Industria</h4>
                 <!-- Check for active role -->
                 <div v-if="_g_UserFeatures.has_active_role">
                    <div class="selected-items-display">
                        <template v-if="sectoresIndustrias.length === 0">
                            <span class="no-selection">Ninguno seleccionado</span>
                        </template>
                        <template v-else>
                            <span class="selected-tag" v-for="item in sectoresIndustrias" :key="`sector-${item}`">{{ item }}</span>
                        </template>
                    </div>
                     <button 
                        @click="showSectorModal = true" 
                        class="action-btn secondary-btn select-btn"
                        :disabled="!_g_UserFeatures.permissions.sector_filter"
                    >
                         <i class="material-icons">edit</i>
                         <span>Seleccionar Sectores/Industrias</span>
                    </button>
                    <!-- Modal is INSIDE the v-if -->
                    <MultiSelectCheckboxModal
                        :visible="showSectorModal"
                        title="Seleccionar Sectores/Industrias"
                        :options="sectorOptions"
                        :selectedValues="finder.industry || []"
                        @close="showSectorModal = false"
                        @apply-selection="handleSectorSelection"
                    />
                 </div>
                 <!-- v-else is now adjacent -->
                <div v-else>
                    <FilterNoPermission :link="buy_link" />
                </div>
            </div>

        </div>
         <!-- This v-else corresponds to the v-if="finder" -->
         <div v-else class="loading-placeholder">
             <!-- Optional loading state -->
        </div>
    </div>
</template>

<script>
// import FourthConfig from "@/components/stockchart/components/Finder/configs/Fourth.json"; // Config no longer directly used in template
// Remove old SelectOutlineFourth import
// import SelectOutline from "@/components/helpers/SelectOutlineFourth";
import FilterNoPermission from "@/components/stockchart/components/Finder/FilterNoPermission";
import MultiSelectCheckboxModal from "@/components/helpers/MultiSelectCheckboxModal.vue"; // Import the new modal
import { mapMutations, mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    components: {
        FilterNoPermission,
        MultiSelectCheckboxModal, // Register the modal
    },
    name: 'FourthFilterGroup', // Renamed
    props: {
        // filters_user_json prop might not be needed if permissions are checked via _g_UserFeatures
        // filters_user_json:{type: Object, default: () => ({}) }, 
    },
    data: () => ({
        finder: null, // Local copy of the finder object
        buy_link: "",

        // --- Modal Visibility Flags ---
        showCurrencyModal: false,
        showCountryModal: false,
        showSectorModal: false,

        // --- Data for Modals (Formatted Options) ---
        currencyOptions: [], // Array of { value: string, label: string }
        countryOptions: [],
        sectorOptions: [],

        // --- Data for Displaying Selections --- 
        divisas: [], // Array of selected labels for display
        paises: [],
        sectoresIndustrias: [],

        // --- Raw API Data Mapping (for reverse lookups) ---
        currencyMap: {}, // e.g., { 'USD': 'USD', 'EUR': 'EUR', ... }
        countryMap: {}, // e.g., { 'US': 'United States', 'ES': 'Spain', ... }
        sectorMap: {}, // e.g., { '10': 'Energy', '15': 'Materials', ... }
    }),
    watch: {
         // Watcher for global finder changes
         _g_FinderFilters: {
            handler(newFinder) {
                // console.log("Fourth.vue: _g_FinderFilters watcher triggered", newFinder);
                if (newFinder && typeof newFinder === 'object' && Object.keys(newFinder).length > 0) {
                    // Create a deep copy only if it's a valid object
                    this.finder = JSON.parse(JSON.stringify(newFinder)); 
                    // Ensure necessary arrays exist on the local finder copy
                    this.finder.currency = this.finder.currency || [];
                    this.finder.country = this.finder.country || [];
                    this.finder.industry = this.finder.industry || [];
                    // Update display based on the new finder state
                    this.updateDisplayArrays(); 
                } else {
                    // Handle reset case or initial undefined state
                    this.finder = { currency: [], country: [], industry: [] }; // Initialize with empty arrays
                    this.divisas = [];
                    this.paises = [];
                    this.sectoresIndustrias = [];
                     // console.log("Fourth.vue: Finder reset or invalid", this.finder);
                }
            },
            immediate: true, // Run on component mount to get initial state
            deep: true // Watch for nested changes
        }
    },
    computed: {
       ...mapGetters(['_g_crypto_enable', '_g_UserFeatures', '_g_FinderFilters']),
        crypto_enabled() { // Keep if needed by template conditions
            return this._g_crypto_enable;
        },
        // Helper computed property to check if all currencies are selected
        allCurrenciesSelected() {
             // Check against available options, excluding the 'All' option if it has value '0'
            const allOptionValue = '0'; 
            const availableValues = this.currencyOptions.map(opt => opt.value).filter(v => v !== allOptionValue);
            if (!this.finder || !this.finder.currency || availableValues.length === 0) return false;
            
            // Condition 1: Finder explicitly contains '0'
            if (this.finder.currency.includes(allOptionValue)) return true;
            
            // Condition 2: All available options (excluding '0') are selected
            const selectedValuesSet = new Set(this.finder.currency);
            return availableValues.every(val => selectedValuesSet.has(val));
        },
         // Similar helpers for Countries and Sectors
        allCountriesSelected() {
             const allOptionValue = '0'; 
             const availableValues = this.countryOptions.map(opt => opt.value).filter(v => v !== allOptionValue);
            if (!this.finder || !this.finder.country || availableValues.length === 0) return false;
             if (this.finder.country.includes(allOptionValue)) return true;
             const selectedValuesSet = new Set(this.finder.country);
             return availableValues.every(val => selectedValuesSet.has(val));
        },
        allSectorsSelected() {
             const allOptionValue = '0'; 
            const availableValues = this.sectorOptions.map(opt => opt.value).filter(v => v !== allOptionValue);
            if (!this.finder || !this.finder.industry || availableValues.length === 0) return false;
             if (this.finder.industry.includes(allOptionValue)) return true;
            const selectedValuesSet = new Set(this.finder.industry);
            return availableValues.every(val => selectedValuesSet.has(val));
        }
    },
    created() {
        // Fetch available options when component is created
        this._getAvailableCurrencies();
        this._getAvailableCountries();
        this._getAvailableSectors();
    },
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
    },
    methods: {
        ...mapMutations(['setFinderFilters']), // Keep mutation
        // Removed old/unused methods: _getValueFromSelect, _remove_element, _show_select, _getKeyByValue, _postAvailable..., _deleteAvailable..., _refreshFilters..., _deleteAll...

        // --- Helper to Sort Options with 'All' First ---
        _sortOptionsWithAllFirst(map, allValue = '0') {
            let options = Object.entries(map)
                           .map(([key, label]) => ({ value: key, label: label }));
            
            const allOptionIndex = options.findIndex(opt => opt.value === allValue);
            let allOption = null;
            
            if (allOptionIndex > -1) {
                allOption = options.splice(allOptionIndex, 1)[0]; // Remove 'All' option
            }
            
            // Sort remaining options alphabetically
            options.sort((a, b) => a.label.localeCompare(b.label)); 
            
            // Add 'All' option back to the beginning if it existed
            if (allOption) {
                options.unshift(allOption);
            }
            
            return options;
        },

        // --- API Fetch and Formatting --- 
        _getAvailableCurrencies: function(){
            let success = (response) => {      
                this.currencyMap = response.data || {};
                this.currencyOptions = this._sortOptionsWithAllFirst(this.currencyMap);
                this.updateDisplayArrays(); // Update display based on current finder state
            };
            this._fetchData("/api/v1/stock-finder/available-currencies", success, '122', 'Error recuperando divisas.');
        },
        _getAvailableCountries: function(){
            let success = (response) => {
                this.countryMap = response.data || {};
                this.countryOptions = this._sortOptionsWithAllFirst(this.countryMap);
                this.updateDisplayArrays();
            };
            this._fetchData("/api/v1/stock-finder/available-countries", success, '127', 'Error recuperando países.');
        },
        _getAvailableSectors: function(){
            let success = (response) => {
                this.sectorMap = response.data || {};
                this.sectorOptions = this._sortOptionsWithAllFirst(this.sectorMap);
                this.updateDisplayArrays();
            };
             this._fetchData("/api/v1/stock-finder/available-industries", success, '132', 'Error recuperando sectores/industrias.');
        },
        // Helper for API calls
        _fetchData: function(url, successCallback, failureCode, failureMessage) {
            const successHandler = new APICaller.SuccessHandler(successCallback);
            const failureHandler = new APICaller.FailureHandler(failureCode, failureMessage);
            const apiCallConfig = { loadingOverlay: false };
            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },

        // --- Update Display based on Finder --- 
        updateDisplayArrays() {
            // Ensure finder and maps are populated before updating display
            if (!this.finder || !this.currencyMap || !this.countryMap || !this.sectorMap) {
                 // console.warn("Fourth.vue: Cannot update display arrays, finder or maps not ready.");
                 return;
             }
            // console.log("Fourth.vue: Updating display arrays. Finder state:", JSON.parse(JSON.stringify(this.finder)));
            
            // Update Divisas display
             // Use the computed property to check if 'All' should be displayed
            if (this.allCurrenciesSelected) {
                this.divisas = ['Todas']; 
            } else {
                // Ensure finder.currency is an array before mapping
                this.divisas = (Array.isArray(this.finder.currency) ? this.finder.currency : [])
                                 .map(key => this.currencyMap[key]) // Get label from map using key
                                 .filter(Boolean); // Remove undefined if key not found
            }

            // Update Paises display
             if (this.allCountriesSelected) {
                this.paises = ['Todos']; 
            } else {
                 this.paises = (Array.isArray(this.finder.country) ? this.finder.country : [])
                                .map(key => this.countryMap[key])
                                .filter(Boolean);
            }

            // Update Sectores/Industrias display
            if (this.allSectorsSelected) {
                this.sectoresIndustrias = ['Todos'];
            } else {
                  this.sectoresIndustrias = (Array.isArray(this.finder.industry) ? this.finder.industry : [])
                                           .map(key => this.sectorMap[key])
                                           .filter(Boolean);
            }
            // console.log("Fourth.vue: Updated display arrays:", this.divisas, this.paises, this.sectoresIndustrias);
        },

        // --- Modal Apply Handlers --- 
        handleCurrencySelection(selectedValues) {
             if (!this.finder) { console.error("Cannot handle currency selection, finder is null."); return; }
            // Determine if 'All' should be set
             const allOptionValue = '0';
             const allAvailableValues = this.currencyOptions.map(opt => opt.value).filter(v => v !== allOptionValue);
             const allSelected = selectedValues.length > 0 && selectedValues.length === allAvailableValues.length;
            
            // Update finder: use ['0'] if all selected, otherwise use the actual values
            // Ensure finder.currency exists
            this.finder.currency = allSelected ? [allOptionValue] : [...selectedValues]; // Use spread for new array
            
            this.updateDisplayArrays(); // Update the displayed tags/list
            this._updateLastSearch2();
        },
        handleCountrySelection(selectedValues) {
             if (!this.finder) { console.error("Cannot handle country selection, finder is null."); return; }
             const allOptionValue = '0';
             const allAvailableValues = this.countryOptions.map(opt => opt.value).filter(v => v !== allOptionValue);
             const allSelected = selectedValues.length > 0 && selectedValues.length === allAvailableValues.length;

             this.finder.country = allSelected ? [allOptionValue] : [...selectedValues];
             this.updateDisplayArrays();
             this._updateLastSearch2();
        },
        handleSectorSelection(selectedValues) {
            if (!this.finder) { console.error("Cannot handle sector selection, finder is null."); return; }
             const allOptionValue = '0';
             const allAvailableValues = this.sectorOptions.map(opt => opt.value).filter(v => v !== allOptionValue);
             const allSelected = selectedValues.length > 0 && selectedValues.length === allAvailableValues.length;
            
            this.finder.industry = allSelected ? [allOptionValue] : [...selectedValues];
            this.updateDisplayArrays();
            this._updateLastSearch2();
        },
        
        // --- Update Global State --- 
        _updateLastSearch2: function(){
             if (!this.finder) { console.error("Cannot update last search, finder is null."); return; }
            // console.log("Fourth.vue: Calling _updateLastSearch2 with finder:", JSON.parse(JSON.stringify(this.finder)));
            this.setFinderFilters(JSON.parse(JSON.stringify(this.finder)));
            this.$emit("updateLastSearch"); // Notify parent
        }, 
    },
}
</script>

<style lang="scss" scoped>
/* Styles inherited from Finder-Base.vue unscoped block */
/* No need to redefine .filter-group-title, .action-btn, .secondary-btn here */

/* Specific layout for the Fourth filter section */
.fourth-group {
    /* This is the main container within the .filter-section provided by Finder-Base */
    /* No specific styles needed unless overriding section padding/margin */
    display: flex;        /* Use flexbox for the groups within this column */
    flex-direction: column;
    gap: 20px;            /* Use the standard gap between filter groups */
}

/* Remove .filter-sub-group styles as we now use .filter-group */
/* 
.filter-sub-group {
    border: 1px solid var(--color-outline-variant, #e0e0e0); 
    border-radius: 6px;
    padding: 12px 16px;
    margin-bottom: 15px; 
    background-color: var(--color-surface, #F3F4F7); 
}
.filter-sub-group:last-child {
    margin-bottom: 0;
}
.filter-sub-group .filter-group-title {
     border-bottom: none; 
     padding-bottom: 0;
     margin-bottom: 8px;
     font-size: 13px; 
     font-weight: 600;
     color: var(--color-text-primary); 
}
*/

/* Styles for elements within the .filter-group */

/* Style the button used to open the modal */
.select-btn {
    /* Inherits base .action-btn styles */
    /* Apply .secondary-btn styling - make it match the dark buttons in other components */
    background-color: var(--color-muted, #30303d); /* Dark background to match other buttons */
    color: var(--color-text-primary, white); /* Light text for contrast */
    border: none; /* No border on dark buttons */
    font-size: 13px;
    height: 36px; /* Match input height */
    padding: 0 12px; /* Adjust padding */
    width: 100%; /* Make button full width of its container */
    margin-top: 10px; /* Space above button - Use standard gap */
    text-transform: none; /* Override uppercase from action-btn */
    justify-content: center; /* Center content */
    cursor: pointer; /* Add pointer cursor to indicate it's clickable */
    border-radius: 4px; /* Add rounded corners for consistency */
    display: flex; /* Ensure flexbox is used for alignment */
    align-items: center; /* Properly center the icon and text vertically */
}
.select-btn:hover:not(:disabled) {
    background-color: var(--color-active-surface, #3f3f4e); /* Slightly lighter on hover */
    border-color: transparent;
}
.select-btn:disabled {
    /* Inherits disabled style from .action-btn */
    opacity: 0.6;
    cursor: not-allowed; /* Change cursor for disabled state */
}
.select-btn i {
    font-size: 18px;
    margin-right: 6px; /* Ensure gap from base style is applied */
    line-height: 1; /* Ensure icon proper alignment */
}

/* Display area for selected items */
.selected-items-display {
    min-height: 36px; /* Match button/input height */
    margin-bottom: 0; /* Display area is directly above button now, use button margin */
    padding: 6px 10px; /* Match input padding */
    background-color: var(--color-background, white); /* Match input background */
    border-radius: 4px; /* Match input border-radius */
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12)); /* Match input border */
    display: flex;
    flex-wrap: wrap;
    align-items: center; /* Vertically align items */
    gap: 4px 6px; /* Row and column gap */
    font-size: 13px; /* Match input font size */
    color: var(--color-text-secondary);
    cursor: default; /* Indicate it's not directly editable */
    overflow: hidden; /* Prevent overflow */
}

.no-selection {
    font-style: italic;
    opacity: 0.7;
    line-height: 1.5; /* Adjust line height */
    padding: 1px 0; /* Add minimal padding to better align vertically with tags */
}

.selected-tag {
    background-color: var(--color-active-surface, #e0e0e0); /* Use active surface color */
    color: var(--color-text-primary);
    padding: 3px 8px; /* Adjust padding */
    border-radius: 12px; /* Pill shape */
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px; /* Slightly smaller */
    line-height: 1.4;
    max-width: 150px; /* Limit width to prevent overflow */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for text truncation */
    display: inline-block; /* Necessary for text-overflow to work */
}

/* Loading placeholder - inherits from unscoped styles */
.loading-placeholder {
    /* Styles defined in Finder-Base.vue */
}

</style>