<template>
    <transition name="modal-fade">
      <div v-if="visible" class="modal-overlay multi-select-overlay" @click.self="closeModal">
        <div class="modal-container multi-select-modal">
          <!-- Modal Header -->
          <div class="modal-header">
              <div class="title-wrapper">
                   <span class="material-icons header-icon">checklist</span> 
                  <h3 class="modal-title">{{ title }}</h3>
              </div>
              <button class="modal-close-btn" @click="closeModal">
                  <i class="material-icons">close</i>
              </button>
          </div>
  
          <!-- Modal Body -->
          <div class="modal-body multi-select-body">
              <!-- Search Input -->
              <div class="search-input-wrapper">
                   <span class="material-icons search-icon">search</span>
                  <input 
                      type="text" 
                      v-model="searchTerm" 
                      placeholder="Buscar opciones..." 
                      class="vanilla-input search-input"
                      ref="searchInput"
                  />
                   <button v-if="searchTerm" @click="searchTerm = ''" class="clear-search-btn" title="Limpiar búsqueda">
                       <i class="material-icons">clear</i>
                   </button>
              </div>
  
              <!-- Options List -->
               <div class="options-list-container">
                   <div v-if="filteredOptions.length > 0" class="options-list">
                       <div 
                          v-for="option in filteredOptions" 
                          :key="option.value" 
                          class="checkbox-item option-item"
                       >
                           <input 
                              type="checkbox" 
                              :id="`msm-${option.value}`" 
                              :value="option.value"
                              v-model="internalSelectedValues"
                              class="vanilla-checkbox"
                           >
                           <label :for="`msm-${option.value}`" class="checkbox-label option-label">
                               {{ option.label }}
                           </label>
                       </div>
                   </div>
                   <div v-else class="no-options-message">
                       No hay opciones que coincidan con la búsqueda.
                   </div>
               </div>
          </div>
  
          <!-- Modal Footer -->
          <div class="modal-footer">
              <button @click="clearSelection" class="action-btn clear-all-btn">
                   <i class="material-icons">delete_sweep</i>
                  <span>Limpiar Selección</span>
              </button>
              <div class="footer-actions">
                  <button @click="closeModal" class="action-btn secondary-btn">
                      Cancelar
                  </button>
                  <button @click="applyChanges" class="action-btn primary-btn">
                       <i class="material-icons">check</i>
                      <span>Aplicar</span>
                  </button>
              </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'MultiSelectCheckboxModal',
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: 'Seleccionar Opciones',
      },
      options: {
        type: Array, // Expected format: [{ value: any, label: string }, ...]
        required: true,
        default: () => [],
      },
      selectedValues: {
        type: Array, // Array of the *values* that are selected
        required: true,
        default: () => [],
      },
      allOptionValue: {
        type: String,
        default: '0', // Allow customization if needed, default to '0'
      },
    },
    data() {
      return {
        internalSelectedValues: [], // Holds the state within the modal
        searchTerm: '',
        isUpdatingInternally: false, // Flag to prevent watcher recursion
      };
    },
    computed: {
      filteredOptions() {
        if (!this.searchTerm) {
          return this.options;
        }
        const lowerSearchTerm = this.searchTerm.toLowerCase();
        return this.options.filter(option => 
          option.label.toLowerCase().includes(lowerSearchTerm)
        );
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
         this.searchTerm = ''; // Clear search on close
      },
      applyChanges() {
        // Emit only the array of selected values
        this.$emit('apply-selection', [...this.internalSelectedValues]); // Send a copy
        this.closeModal();
      },
      resetInternalSelection() {
         // Copy prop to internal state when modal becomes visible
        this.internalSelectedValues = [...this.selectedValues]; 
         this.searchTerm = ''; // Clear search term as well
         // Auto-focus search input when modal opens
         this.$nextTick(() => {
             this.$refs.searchInput?.focus();
         });
      },
       clearSelection() {
           this.internalSelectedValues = [];
       }
    },
    watch: {
      visible(newValue) {
        if (newValue) {
          this.resetInternalSelection();
        }
      },
      internalSelectedValues(newValue, oldValue) {
        if (this.isUpdatingInternally) {
            return; // Prevent recursion if change was initiated by the watcher
        }

        const justAdded = newValue.filter(x => !oldValue.includes(x));
        const allValue = this.allOptionValue; // Use prop for 'Todos' value

        let newSelection = [...newValue];
        let changed = false;

        // Case 1: 'All' was just added
        if (justAdded.includes(allValue) && newValue.length > 1) {
            newSelection = [allValue]; // Select only 'All'
            changed = true;
        }
        // Case 2: Another option was just added while 'All' was selected
        else if (justAdded.length > 0 && !justAdded.includes(allValue) && newValue.includes(allValue)) {
            newSelection = newSelection.filter(val => val !== allValue); // Remove 'All'
            changed = true;
        }

        if (changed) {
            this.isUpdatingInternally = true;
            this.internalSelectedValues = newSelection;
            this.$nextTick(() => {
                 this.isUpdatingInternally = false; // Reset flag after update
            });
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  /* Import shared variables if possible */
  /* @import '@/styles/_variables.scss'; */
  
  .modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .modal-fade-enter, .modal-fade-leave-to {
    opacity: 0;
  }
  
  .modal-overlay.multi-select-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(15, 15, 26, 0.6); /* Slightly darker overlay */
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically */
    z-index: 1060; /* Higher z-index if needed */
    padding: 20px;
  }
  
  .modal-container.multi-select-modal {
    background-color: var(--color-surface);
    border-radius: 8px;
    width: 90%;
    max-width: 550px; /* Adjust width as needed */
    max-height: 85vh; 
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 25px rgba(15, 15, 26, 0.2);
    color: var(--color-text-primary);
  }
  
  /* Modal Header */
  .modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-outline);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
  }
  
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .header-icon {
    color: var(--primary);
    font-size: 22px;
  }
  
  .modal-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .modal-close-btn {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--color-text-secondary);
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: background-color 0.2s ease-in-out;
  }
  .modal-close-btn:hover {
    background-color: var(--color-active-surface);
  }
  .modal-close-btn i {
    font-size: 20px;
  }
  
  /* Modal Body */
  .modal-body.multi-select-body {
    display: flex;
    flex-direction: column;
    overflow: hidden; 
    flex-grow: 1;
    padding: 16px 24px; 
  }
  
  .search-input-wrapper {
      position: relative;
      margin-bottom: 16px;
      flex-shrink: 0;
  }
  
  .search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-text-secondary);
      font-size: 20px;
      pointer-events: none; /* Don't intercept clicks */
  }
  
  .search-input {
      padding-left: 40px; /* Space for icon */
      padding-right: 40px; /* Space for clear button */
  }
  
  .clear-search-btn {
       position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      color: var(--color-text-secondary);
      cursor: pointer;
      padding: 5px;
       display: flex;
      align-items: center;
      justify-content: center;
  }
  .clear-search-btn i {
      font-size: 18px;
  }
  .clear-search-btn:hover {
       color: var(--color-text-primary);
  }
  
  .options-list-container {
      flex-grow: 1;
      overflow-y: auto; /* Enable scrolling for the list */
      border-radius: 4px;
      margin: 0 -8px; /* Negative margin to align with padding */
      padding: 8px 8px 0 8px; /* Add padding back */
  }
  
  .options-list {
      display: flex;
      flex-direction: column;
      gap: 4px; /* Space between options */
  }
  
  .option-item {
      padding: 6px 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.15s ease;
  }
  .option-item:hover {
       background-color: var(--color-active-surface);
  }
  
  .option-item .vanilla-checkbox {
       width: 16px; /* Slightly smaller */
       height: 16px;
       margin-right: 10px; 
  }
  
  .option-item .vanilla-checkbox::before {
      font-size: 10px; /* Smaller checkmark */
  }
  
  .option-label {
      font-size: 14px;
      line-height: 1.3;
      flex-grow: 1; /* Allow label to take space */
  }
  
  .no-options-message {
      padding: 20px;
      text-align: center;
      color: var(--color-text-secondary);
      font-style: italic;
  }
  
  
  /* Modal Footer */
  .modal-footer {
    padding: 12px 24px;
    border-top: 1px solid var(--color-outline);
    display: flex;
    justify-content: space-between; /* Space out clear and action buttons */
    align-items: center;
    flex-shrink: 0;
  }
  
  .footer-actions {
      display: flex;
      gap: 10px; /* Space between Cancel and Apply */
  }
  
  .clear-all-btn {
       background-color: transparent;
       color: var(--color-text-secondary);
       padding-left: 10px;
       padding-right: 12px;
       border: 1px solid transparent; /* No border initially */
  }
  .clear-all-btn:hover:not(:disabled) {
       background-color: var(--color-active-surface);
       color: var(--color-error);
       border-color: var(--color-outline);
  }
  .clear-all-btn i {
      color: var(--color-error);
      font-size: 20px; /* Larger icon */
      margin-right: 4px;
  }
  
  /* --- Copied Base Styles --- */
  
  /* Base Action Button Styles */
  .action-btn {
    padding: 10px 16px; /* Adjusted padding slightly */
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    display: inline-flex; /* Use flex for alignment */
    align-items: center; /* Vertically center content */
    justify-content: center; /* Horizontally center content */
    gap: 6px; /* Space between icon and text */
    line-height: 1.2; /* Prevent text jumping */
  }
  
  .action-btn i.material-icons {
      font-size: 18px; /* Adjust icon size if needed */
      margin: 0; /* Reset any potential margins */
  }
  
  .primary-btn {
    background-color: var(--primary);
    color: var(--color-primary);
  }
  
  .primary-btn:hover {
    background-color: var(--color-highlight);
  }
  
  .secondary-btn {
    background-color: var(--color-muted);
    color: var(--color-text-primary);
  }
  
  .secondary-btn:hover {
    background-color: var(--color-active-surface);
  }
  
  .clear-all-btn {
    padding-left: 10px; /* Keep specific padding if needed */
    padding-right: 12px;
  }
  
  .clear-all-btn:hover:not(:disabled) {
    color: var(--color-error);
  }
  
  .clear-all-btn i {
    color: var(--color-error);
  }
  
  .action-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  </style>