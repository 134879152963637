import { render, staticRenderFns } from "./Stocks.vue?vue&type=template&id=163a3707&scoped=true&"
import script from "./Stocks.vue?vue&type=script&lang=js&"
export * from "./Stocks.vue?vue&type=script&lang=js&"
import style0 from "./Stocks.vue?vue&type=style&index=0&id=163a3707&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163a3707",
  null
  
)

export default component.exports