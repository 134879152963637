<template>
  <div class="table-custom table-custom">
    <div>
      <table class="full-table">
        <thead class="top-table">
          <tr>
            <th role="columnheader" data-column-id="date" scope="col" class="header-table">Ticker</th>
            <th role="columnheader" data-column-id="date" scope="col" class="header-table">Operaciones</th>
            <th v-for="(item, index) in table_header_anonymous" :key="index" role="columnheader" data-column-id="date" scope="col" class="header-table">{{item}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in table_body_anonymous" :key="index">
            <th scope="row" class="table-row">{{stocks_anonymous[index]}}</th>
            <th scope="row" class="table-row">
              <span class="alarm push mdc-list-item__meta material-icons">schedule</span>
              <span class="favorite mdc-list-item__meta material-icons-outlined" >grade</span>
            </th>
            <th v-for="(cel, key) in row" :key='key' scope="row" class="table-row">{{cel}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
  import TableCustom from "@/components/helpers/Table_custom.vue";
  import Vue from 'vue';

  export default {
    name: 'TableCustom',
    props: {
      table_header_anonymous: {type: Array},
      table_body_anonymous: {type: Array},
      table_obj: {type: Object},
      stocks_anonymous: {type: Array}
    },
    data() {
      return {
      };
    },
    computed: {
    },
    mounted() {
      const MyComponentConstructor = Vue.extend(TableCustom);
      const table = new MyComponentConstructor();
    }  
  }
</script>



<style lang="scss">

#stocks{
  .table-custom{
    height: auto;
    width:auto;
    margin-top:25px;
    margin-bottom:50px;
  }
  .header-table{
    margin-right:50px !important;
  }
}

#best-bullish-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#hot-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#best-sectors{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#portfolio-changes{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#twin-souls{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
  }
  .table-custom{
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#artificial-inteligence{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 139px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#waiting-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}
#last-ipos{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 280px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#stock-table{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    height: 40px;
  }
  .table-custom{
    height: 90vh;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 97.5%;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
    width: auto;
  }
  .top-table{
  }
  .full-table{
    width: -webkit-fill-available;
  }
}


</style>
