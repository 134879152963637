<template>
<!-- Standard Modal Structure -->
<div v-if="_g_ShowStockFinderFilter" class="modal-overlay finder-overlay" @click="_closeWindow()">
    <div id="stocks-finder-modal" class="modal-container finder-modal" @click.stop>
      
      <!-- Modal Header -->
      <div class="modal-header">
          <div class="title-wrapper">
              <span class="material-icons header-icon">filter_list</span> 
              <h3 class="modal-title">Filtros de Acciones</h3>
            </div>     
          <button class="modal-close-btn" @click="_closeWindow()">
              <i class="material-icons">close</i>
          </button>
            </div>

      <!-- Modal Body -->
      <div class="modal-body finder-body">
          <!-- Top Controls: Reset & Save -->
          <div class="finder-top-controls">
              <!-- Reset Button -->
              <button 
                  @click="_g_IsUserLogged ? _resetFinder() : _onToastMessage()" 
                  class="action-btn secondary-btn reset-button"
                  :disabled="!_g_IsUserLogged"
                  title="Reiniciar todos los filtros aplicados">
                  <i class="material-icons">refresh</i>
                  <span>REINICIAR</span>
              </button>

              <!-- Save Search Section -->
              <div class="save-search-section" v-if="_g_IsUserLogged">
                  <div v-if="show_button" class="save-button-container">
                      <button 
                          @click="_showInput()" 
                          class="action-btn primary-btn save-button"
                          title="Guardar la configuración actual de filtros como una búsqueda personalizada">
                          <i class="material-icons">save</i>
                          <span>GUARDAR BÚSQUEDA</span>
                      </button>
                </div>
                  <div v-else class="add-search-form">
                      <input 
                          type="text"
                          v-model="saved_search"
                          placeholder="Nombre de la búsqueda..."
                          class="vanilla-input save-search-input"
                          id="base-pc-save-search-input" 
                          @keyup.enter="_saveSearchToApi()"
                          ref="saveSearchInput"
                      />
                      <button 
                          class="action-btn primary-btn accept-save-button" 
                          @click="_saveSearchToApi()"
                          :disabled="!saved_search.trim()">
                          <i class="material-icons">check</i>
                          <span>Aceptar</span>
                      </button>
                       <button 
                          class="action-btn cancel-save-button" 
                          @click="_cancelOngoingOperations()">
                          <i class="material-icons">cancel</i>
                      </button>
                    </div>
                </div>
               <div v-else class="save-search-section">
                  <button 
                      @click="_onToastMessage()" 
                      class="action-btn primary-btn save-button disabled-button"
                      disabled
                      title="Inicie sesión para guardar búsquedas">
                      <i class="material-icons">save</i>
                      <span>GUARDAR BÚSQUEDA</span>
                  </button>
            </div>         
        </div>

          <!-- Filters Container -->
          <div id="filters-user" class="filters-content" v-if="filters_user_json && Object.keys(filters_user_json).length > 0">
            <First          
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
            />                        
            <Second
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
            />
            <Third
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
            />
            <Fourth
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
            />
            <!-- Conditional Fifth filter -->
            <template v-if="_g_User.roles[21] || _g_User.roles[20]">
                <Fifth          
                :filters_user_json="filters_user_json"
                @updateLastSearch="_isFilteringASearch"
            />                        
            </template>                  
        </div>
           <div v-else class="loading-filters-placeholder">
               Cargando filtros disponibles...
                    </div>

          <!-- Crypto Toggle Section (if not admin/advanced user) -->
          <div class="crypto-section" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
              <div class="crypto-toggle-wrapper" v-if="_g_User['has_cryptos_enabled']">
                   <div class="crypto-container" @click="_g_IsUserLogged ? _getValueFromToggle() : _onToastMessage()" :class="{ 'disabled-toggle': !_g_IsUserLogged }">
                      <label class="switch" :title="_g_IsUserLogged ? 'Incluir/Excluir Criptomonedas' : 'Inicie sesión para usar esta opción'">
                          <input 
                              id="crypto-toggle-checkbox" 
                              type="checkbox" 
                              :checked="crypto_enabled" 
                              :disabled="!_g_IsUserLogged"
                           >                       
                            <span class="slider round"></span>                        
                        </label>
                      <span class="crypto-text">INCLUIR CRIPTOMONEDAS</span>
                    </div>                
                </div>
              <!-- Crypto Module Promo -->
              <div v-else class="crypto-promo">
                  <strong>¿Interesado en el módulo de criptomonedas? Más info <a href="/modulo-cryptos" target="_blank" class="promo-link">AQUÍ</a></strong>
            </div>
        </div>
    </div>
  </div>
</div> 
</template>


<script>
import { mapMutations, mapGetters } from "vuex";
// Remove Toggle import if not used directly after refactor
// import Toggle from "@/components/helpers/Toggle.vue"; 
import First from "@/components/stockchart/components/Finder/First.vue";
import Second from "@/components/stockchart/components/Finder/Second.vue";
import Third from "@/components/stockchart/components/Finder/Third.vue";
import Fourth from "@/components/stockchart/components/Finder/Fourth.vue";
import Fifth from "@/components/stockchart/components/Finder/Fifth.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";
// Remove InputSearches if no longer needed after replacing SaveSearchInput
// import InputSearches from "@/components/stockchart/components/configs/input_searches.json"; 
// Remove SaveSearchInput import
// import SaveSearchInput from '@/components/helpers/newMaterial/SaveSearchInput.vue';


export default {
    mixins: [APICaller, GetStocks],
    name: 'FinderBase', // Renamed component name slightly for clarity
    components: {
        // Removed Toggle and SaveSearchInput
        First,
        Second,
        Third,
        Fourth,
        Fifth,
    },
    data: () => ({
        finder: "", // Keep finder data if needed by children or logic
        filters_user_json: {}, // Store user permissions/filters
        show_button: true, // Controls visibility of save button vs input
        // removed input_searches: InputSearches,
        closingShield: false, // Used for save input logic
        saved_search: "" // v-model for the new vanilla save search input
        // Removed toggle related data if fully handled by vanilla checkbox now
        // toggle_cripto_name: "toggle_cripto",
        // toggle_cripto_id: "toggle_cripto_id",
        // show_criptos: false, // Use crypto_enabled directly?
    }),
    watch: {
        // Watch crypto_enabled directly if show_criptos is removed
        // crypto_enabled: {
        //     handler: function(newVal) {                 
        //         // Logic if needed when crypto status changes externally
        //     },
        //     immediate: true // Trigger on mount if necessary
        // },
        // Removed changes watcher if not used
        // changes: { ... } 
        _g_Stocks: {
            handler: function(){
                // Still get parameters when underlying stocks change if needed
                this._getFinderParameters()
            },
            deep: true
        },
         // Watcher to focus the input when it appears
         show_button(newValue) {
            if (!newValue) { // When input is shown (show_button becomes false)
                this.$nextTick(() => {
                    this.$refs.saveSearchInput?.focus();
                });
            }
        },
        // Clear search input when modal closes
        _g_ShowStockFinderFilter(newValue) {
            if (!newValue) {
                this.saved_search = '';
                this.show_button = true; // Reset save button state
            } else {
                // Fetch parameters when modal opens
                this._getUserAttributtes();
                this._getFinderParameters();
            }
        }
    },
    mounted() { 
        // Fetch data when component mounts or when modal opens (see watcher)
        // this._getUserAttributtes();
        // this._getFinderParameters();
    },
    created: function() {
        // Keep mobile CSS import if needed, though modal should handle layout
        if(this.is_mobile_device || this.is_tablet_device) {
            import("@/components/stockchart/components/Finder/FinderBaseCssFix.css");
        }
    },
    computed: {
        ...mapGetters(['_g_crypto_enable', '_g_ToggleBetweenTableAndChart', '_g_User', '_g_ShowStockFinderFilter', '_g_ArrayFiltersStocks', '_g_ShowStockChartMenu', 'is_mobile_device', "is_tablet_device", "_g_IsUserLogged", "_g_UserId", "_g_open_filters", "_g_toggle_chart_stocks", "_g_Stocks", '_g_FinderFilters']), 
        // Use _g_crypto_enable directly in the template
        crypto_enabled() {
          return this._g_crypto_enable;
        }
    },
    methods: {
        ...mapMutations(['setShowStockFinderFilters', 'setShowStockChartMenu', 'setMenuSelectionOut', 'setOptionMobile', 'setOpenFilters', 'setChartView', 'setStocksView', 'setStocks', 'setRechargeState','setCrypto', 'setFinderFilters']),

        _onToastMessage: function(message = "Para poder utilizar esta herramienta debe iniciar sesión."){
            this.$vueOnToast.pop('error', "Acceso Restringido", message);
        },        
        _closeWindow(){                                   
            // Simply toggle the filter visibility using the mutation                          
                this.setShowStockFinderFilters();
            // Reset save button state on close
            this.show_button = true; 
            this.saved_search = '';

            // Mobile specific logic if needed (keep if necessary)
            // if(this.is_mobile_device || this.is_tablet_device){
            //     this.setOpenFilters();
            //     if (this._g_open_filters == false) {
            //         if(this._g_toggle_chart_stocks == 'chart'){
            //         this.setChartView(false);
            //         } else{
            //         this.setStocksView(false);
            //         }
            //     }        
            // }else{
            //     this.setMenuSelectionOut(0); // Desktop specific logic if needed
            // }                                                                               
        },
        _getValueFromToggle(){
            // Logic remains largely the same, but ensures it uses the correct state
            if(this._g_IsUserLogged){
                // Determine the action based on the *current* state before toggle
                const enableCrypto = !this.crypto_enabled; 
                
                   let success = () => {
                    this.setCrypto(); // Toggle the state in Vuex
                    this.getStocks(); // Refetch stocks based on new crypto state                         
                    };

                    let url = "/api/v1/stock-finder/change-search-mode";
                const failureMessage = `Error ${enableCrypto ? 'habilitando' : 'deshabilitando'} criptomonedas.`;
                const failureCode = enableCrypto ? '103' : '104';

                    const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler(failureCode, failureMessage);

                // API call likely doesn't need parameters, just toggles backend state
                    this._getAPICall(url, successHandler, failureHandler);

            }
            // else case handled by the click handler directly
        },
        _getFinderParameters: function(){
            if (!this._g_IsUserLogged) {
              // Don't fetch if not logged in, maybe set default/empty filters?
              // this.setFinderFilters({}); 
              return; 
            }
            let success = (response) => {               
                // this.finder = response.data; // Keep if needed
                this.setFinderFilters(response.data);              
            };

            let url = "/api/v1/stock-finder/default-finder";

            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('105', 'Error cargando filtros por defecto.');
            const apiCallConfig = {loadingOverlay: false}; // Don't show global overlay for this

            this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },
        _getUserAttributtes: function(){
           if (this._g_User && this._g_User.permissions && this._g_User.permissions.permissions) {
                this.filters_user_json = this._g_User.permissions.permissions;
            } else {
                this.filters_user_json = {}; // Ensure it's an object even if data is missing
                console.warn("User permissions data not found.");
            }
        },
        _resetFinder: function(){
            if(this._g_IsUserLogged){
                // Consider adding confirmation dialog?
                window.sessionStorage.removeItem('ticker_search_by_user');
                let success = () => {                
                    // Reload or just refetch data? Reload is simpler.            
                    // this.$router.push('/app/graficador-dinamico'); // Navigate if needed
                    // window.location.reload(); // Force reload
                    
                    // Alternative: Reset Vuex state and refetch data without reload
                    this._getFinderParameters(); // Refetch default filters
                    this.getStocks(); // Refetch stocks with default filters
                    this.$vueOnToast.pop("success", "Filtros Reiniciados", "Se han restaurado los filtros por defecto.");

                };

                let url = "/api/v1/stock-finder/delete-last-search";

                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('106', 'Error reiniciando la búsqueda.');

                this._getAPICall(url, successHandler, failureHandler);
            }
            // No else needed as button is disabled if not logged in
        },
        // _isFilteringASearch might need adjustments depending on how child components emit events
        _isFilteringASearch: function(){
            // This method seems to trigger _updateLastSearch2 after a child updates a filter.
            // It also checks for route params, which might be less relevant in a modal context.
            // We might need to simplify this or adjust based on modal behavior.
            // console.log("Filter updated in child, triggering search update...");
            
            // If the route param logic is specifically for deep linking, it might stay.
            // if(this.$route.params.param1 != undefined){
            //     this._getDefaultStocks(); // What does this do? Ensure it's still needed.
            // }
            
            this._updateLastSearch2(); // Assume this is the main action needed.
        },
        _updateLastSearch2: function(){
            // This saves the current filter state to the backend and refreshes stocks.
            if (!this._g_IsUserLogged) return; // Don't update if not logged in

            var params = new URLSearchParams();
            // Access as a computed property, not a function
            const currentFilters = this._g_FinderFilters; 
            
            // Ensure filters are not empty before stringifying
            if (!currentFilters || Object.keys(currentFilters).length === 0) {
              console.warn("Attempted to update last search with empty filters.");
              // Maybe fetch default filters first? Or show a message?
              // this._getFinderParameters(); // Fetch defaults if empty?
              return;
            }

            params.append('finder', JSON.stringify(currentFilters));

            let success = (response) => {
                // console.log("Last search updated successfully.");
                this.getStocks(); // Refetch stocks with the updated filters
            };
            
            // Handle route param logic if needed, otherwise remove
            // if(this.$route.params.param1 != undefined){
            //     this._getDefaultStocks(); 
            // } else {
            //     this.getStocks(); 
            // }

            let url = "/api/v1/stock-finder/update-last-search";
            
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('265', 'Error actualizando filtros.');
            const apiCallConfig = {loadingOverlay: false}; // Don't show global overlay

            this._postAPICall(url, params, successHandler, failureHandler, apiCallConfig);
        },
        _showInput: function(){
            this.show_button = false; // Hide button, show input
             // Focus handled by watcher now
        },
        _saveSearchToApi: function(){          
            if (!this.saved_search.trim() || !this._g_IsUserLogged) return;

            const encoded_search_name = this.encode_finder_name(this.saved_search.trim());
            const currentFilters = this._g_FinderFilters;

             if (!currentFilters || Object.keys(currentFilters).length === 0) {
              this.$vueOnToast.pop("warn", "Guardar Búsqueda", "No hay filtros activos para guardar.");
              return;
            }
            
            var params = new URLSearchParams();
            params.append('name', encoded_search_name);
            params.append('finder', JSON.stringify(currentFilters));

            let success = () => {
                this.$vueOnToast.pop("success", "Búsqueda Guardada", `"${this.saved_search.trim()}" guardada con éxito!`);
                this._cancelOngoingOperations(); // Hide input, show button
            };

            let url = "/api/v1/stock-finder/create-finder";
            
            const successHandler = new APICaller.SuccessHandler(success);
            const failureHandler = new APICaller.FailureHandler('292', 'Error guardando búsqueda.');

            this._postAPICall(url, params, successHandler, failureHandler);
        },
        // Removed _saveSearch - v-model handles input now
        // _saveSearch(value) { ... } 
        
        _cancelOngoingOperations: function(){
           // Hides the save input field and shows the button again
            this.saved_search = ''; // Clear input
                    this.show_button = true;
            // Removed timeout and closingShield logic as it seems overly complex for simple show/hide
        },
        // Removed _turnOnClosingShield
        // _turnOnClosingShield: function(){ ... }

        encode_finder_name: function(name) {
            // Keep this utility function
            return name.replace(/\s+/g, 'Q1SPACEQ1');
        },
    },
}
</script>

<!-- UNscoped styles for child components -->
<style lang="scss">
/* Styles here will apply to FinderBase AND its children (First, Second, etc.) */

/* Common Filter Group Layout */
.filter-section {
    /* Add padding or margins if needed */
     width: 100%; /* Ensure sections take available width within flex item */
}

.filter-group {
    margin-bottom: 20px; /* Space between groups within a section */
}

.filter-group:last-child {
    margin-bottom: 0; /* No margin for the last group in a section */
}

.filter-group-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600; 
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--color-text-secondary, #606577); 
    margin: 0 0 10px 0; /* Bottom margin */
    border-bottom: 1px solid var(--color-outline-variant, #e0e0e0); /* Add a subtle separator */
    padding-bottom: 6px;
}

/* Shared Input/Select/Checkbox Styles */

.input-wrapper, .select-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px; /* Space between label and input */
    flex: 1; /* Allow elements in pairs to share space */
    min-width: 120px; /* Minimum width before wrapping */
}

.input-label {
    font-size: 12px;
    color: var(--color-text-secondary, #606577);
    font-weight: 500;
}

.vanilla-input, .vanilla-select {
    height: 36px; 
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    border-radius: 4px; 
    background-color: var(--color-background, white);
    color: var(--color-text-primary, #0F0F1A);
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    box-sizing: border-box;
    width: 100%;
}

.vanilla-input:focus, .vanilla-select:focus {
    outline: none;
    border-color: var(--primary, #00AEDD);
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}

.vanilla-input::placeholder {
    color: var(--color-text-secondary, #606577);
    opacity: 0.6;
}

.vanilla-input:disabled, .vanilla-select:disabled {
    background-color: var(--color-surface, #F3F4F7);
    cursor: not-allowed;
    opacity: 0.7;
}

.vanilla-select {
    appearance: none;
    -webkit-appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23606577%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 10px auto;
    padding-right: 30px;
}

/* Checkbox grid layout */
.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px 15px; 
    align-items: center; 
}

/* Paired inputs/selects layout */
.pair-container {
    display: flex;
    gap: 10px; 
    flex-wrap: wrap; 
}

.checkbox-item {
    display: flex;
    align-items: center; 
    position: relative; 
}

.vanilla-checkbox {
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border: 1.5px solid var(--color-outline, #ccc);
    border-radius: 4px;
    background-color: var(--color-surface, white);
    cursor: pointer;
    margin: 0;
    margin-right: 8px;
    display: inline-block;
    position: relative;
    flex-shrink: 0;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.vanilla-checkbox::before {
    content: '\2714'; /* Reverted to single backslash */
    font-size: 12px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.vanilla-checkbox:checked {
    background-color: var(--primary, #00aedd);
    border-color: var(--primary, #00aedd);
}

.vanilla-checkbox:checked::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.vanilla-checkbox:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 174, 221, 0.2);
}

.vanilla-checkbox:disabled {
    background-color: var(--color-muted, #EAECEF);
    border-color: var(--color-outline, #ccc);
    cursor: not-allowed;
    opacity: 0.7;
}

.vanilla-checkbox:disabled:checked {
    background-color: var(--color-text-inactive, #B0BEC5);
    border-color: var(--color-text-inactive, #B0BEC5);
}

.vanilla-checkbox:disabled + .checkbox-label {
    cursor: not-allowed;
    color: var(--color-text-inactive, #90A4AE);
}

.checkbox-label {
    font-size: 13px;
    color: var(--color-text-primary, #333);
    cursor: pointer;
    user-select: none;
    line-height: 1.2;
}

.loading-placeholder {
    padding: 20px;
    text-align: center;
    color: var(--color-text-secondary, #606577);
    font-style: italic;
}

/* --- Add other common styles needed by First.vue, Second.vue etc. here --- */

</style>

<!-- Scoped styles for FinderBase itself -->
<style lang="scss" scoped>
/* Import shared variables/mixins if available */
/* @import '@/styles/variables.scss'; */

/* Modal Overlay */
.modal-overlay.finder-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 15, 26, 0.5); /* Darker overlay */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align to top */
  z-index: 1050; /* Ensure it's above other elements */
  padding-top: 5vh; /* Add some top padding */
}

/* Modal Container */
.modal-container.finder-modal {
  background-color: var(--color-background, white);
  border-radius: 8px;
  width: 95%; /* Use more width */
  max-width: 1200px; /* Increase max-width significantly */
  max-height: 90vh; /* Limit height */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 25px rgba(15, 15, 26, 0.2);
  color: var(--color-text-primary, #0F0F1A);
}

/* Modal Header */
.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon {
  color: var(--primary, #00AEDD);
  font-size: 24px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
            font-weight: 600;
        }

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-secondary, #606577);
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: var(--color-surface, #F3F4F7);
}

.modal-close-btn i {
  font-size: 20px;
}

/* Modal Body */
.modal-body.finder-body {
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent body overflow */
  flex-grow: 1;
  padding: 0; /* Remove padding, handle inside sections */
}

/* Top Controls Section */
.finder-top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    flex-shrink: 0;
    gap: 15px; /* Space between elements */
}

.reset-button {
    padding-left: 12px; /* Adjust padding for icon */
    padding-right: 16px;
}

.reset-button i {
    margin-right: 6px;
    font-size: 20px;
}

.save-search-section {
    display: flex;
    align-items: center;
}

.save-button-container {
   /* No specific styles needed unless for alignment */
}

.save-button {
    padding-left: 12px; /* Adjust padding for icon */
    padding-right: 16px;
}

.save-button i {
    margin-right: 6px;
    font-size: 20px;
}

.disabled-button {
    opacity: 0.6;
    cursor: not-allowed;
}

.add-search-form {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Reference .vanilla-input from unscoped styles */
.vanilla-input.save-search-input {
    height: 36px; /* Match button height */
    width: 200px; /* Adjust width as needed */
}

.accept-save-button i, .cancel-save-button i {
            font-size: 20px;
}
.accept-save-button span{
    margin-left: 4px;
}

/* Use secondary button style for cancel */
.cancel-save-button {
    background-color: transparent;
    color: var(--color-text-secondary, #606577);
    border: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    padding: 0 8px; /* Smaller padding for icon-only */
    min-width: 36px; /* Ensure circular shape */
}
.cancel-save-button:hover {
     background-color: var(--color-surface, #F3F4F7);
     border-color: var(--color-text-secondary, #606577);
}


/* Filters Content Area */
.filters-content {
    padding: 20px 24px;
    overflow-y: auto; /* Enable vertical scrolling for the whole area if needed */
    flex-grow: 1; /* Take remaining space */
    display: flex; /* Arrange children (First, Second...) horizontally */
    /* flex-wrap: wrap; */ /* Prevent wrapping to enforce horizontal layout */
    gap: 0; /* Remove gap, use border/padding for separation */
}

/* Give each direct child (filter component) a basis and border */
.filters-content > * {
    flex: 1 1 0px; /* Allow shrinking, don't allow growing initially, basis 0 */
    min-width: 200px; /* Minimum width before shrinking */
    border-right: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12)); /* Vertical divider */
    padding: 0 20px; /* Add padding inside each column */
    overflow-y: auto; /* Allow individual columns to scroll if content overflows */
    max-height: calc(90vh - 150px); /* Adjust based on header/footer/padding height */
}

/* First child padding */
.filters-content > *:first-child {
    padding-left: 0;
}

/* Remove border from the last child */
.filters-content > *:last-child {
    border-right: none;
    padding-right: 0;
}


.filter-divider {
    /* This class is now only used *within* child components, not between them */
    /* Styling moved to unscoped style block */
}

/* Reference .loading-filters-placeholder from unscoped */
/* .loading-filters-placeholder { ... } */

/* Crypto Section */
.crypto-section {
    padding: 16px 24px;
    border-top: 1px solid var(--color-outline, rgba(0, 0, 0, 0.12));
    flex-shrink: 0; /* Prevent shrinking */
}

.crypto-toggle-wrapper {
    display: flex;
    justify-content: flex-start; /* Align to the left */
}

.crypto-container {
    display: inline-flex; /* Use inline-flex for alignment */
    align-items: center;
    gap: 12px; /* Space between toggle and text */
        cursor: pointer;
    padding: 4px 8px; /* Add some padding for hover effect */
    border-radius: 18px; /* Match toggle radius */
    transition: background-color 0.2s ease;
}
.crypto-container:not(.disabled-toggle):hover {
    background-color: var(--color-surface, #F3F4F7);
}

.crypto-container.disabled-toggle {
    cursor: not-allowed;
    opacity: 0.6;
}


.crypto-text {
    font-size: 13px;
    font-weight: 600;
    color: var(--color-text-secondary, #606577);
    user-select: none; /* Prevent text selection */
    line-height: 1; /* Align text better with toggle */
}

/* Vanilla Toggle Switch Styles */
/* Moved to unscoped style block */

.crypto-promo {
    text-align: center; 
    padding: 10px 0 0 0;
    font-size: 14px;
    color: var(--color-text-secondary, #606577);
}
.promo-link {
    color: var(--primary, #00aedd);
    text-decoration: none;
    font-weight: 600;
}
.promo-link:hover {
    text-decoration: underline;
}


/* Base Action Button Styles (copied from TickerFinder for consistency) */
.action-btn {
    border: none;
    border-radius: 20px; /* Consistent radius */
    cursor: pointer;
    font-size: 13px; /* Slightly smaller font */
    padding: 0 16px;
    height: 36px;
    transition: all 0.2s ease;
    font-weight: 600;
    display: inline-flex; /* Align icon and text */
    align-items: center;
    justify-content: center;
    gap: 6px; /* Space between icon and text */
    text-transform: uppercase; /* Uppercase text like original */
    letter-spacing: 0.5px;
}
.action-btn i { /* Icon size */
    font-size: 18px; 
}

.primary-btn {
    background-color: var(--primary, #00aedd);
    color: white;
}
.primary-btn:hover:not(:disabled) {
    background-color: #0095c5; /* Darker primary */
}

/* Secondary Button Style (for Reset) */
.secondary-btn {
    background-color: var(--color-surface-variant, #E8EAF6); /* Use a surface variant */
    color: var(--primary-dark, #007A9E); /* Use a darker primary text */
    border: 1px solid var(--color-outline-variant, #C5CAE9); /* Subtle border */
}
.secondary-btn:hover:not(:disabled) {
    background-color: #daddf0; /* Slightly darker surface */
    border-color: #adb5d6;
}

.action-btn:disabled {
    background-color: var(--color-muted, #EAECEF); 
    color: var(--color-text-inactive, rgba(0,0,0,0.4));
    cursor: not-allowed;
    border-color: transparent; /* Remove border for disabled secondary */
}

/* Reference .vanilla-input from unscoped */
/* .vanilla-input { ... } */


/* --- Remove or adapt old styles --- */
/* Remove styles related to the old fixed position sidebar layout */
/* .separator, .stocks-finder, .fullscreen-filters, etc. */
/* Adapt styles for .cripto-container, .reset-button, .save-button-container, etc. */

/* Example of adapting old style */
// .reset-button{ 
    /* OLD Styles */
    /* border-radius: 50px !important; 
    color:white !important;
    background-color: #00aedd;
    border:none !important;
    height:35px !important;
    cursor: pointer !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
    font-size: 15px !important;
    margin-top:21px !important;
    margin-bottom: 25px !important;
    padding: 3px 50px !important;
    box-sizing: content-box !important; */
    /* NEW styles are handled by .action-btn.secondary-btn */
// }

/* Remove media queries specific to the old layout if modal handles responsiveness */
/* @media (max-width: 900px){ ... } */
/* @media (min-width: 901px) { ... } */

/* Keep specific adjustments if necessary */
/* Reference #filters-user from unscoped */
/* #filters-user { ... } */

/* Ensure hr is styled consistently */
/* Handled by .filter-divider now in unscoped */

</style>