<template>
    <div class="filter-section">
        <div class="filters-container third-filters-container" v-if="local_array_stocks_filters.length != 0">
            
            <!-- Row One: Specific Filters -->
            <div class="filter-row">
                <!-- Rentabilidad Específica Group -->
                <div class="filter-group rentabilidad-especifica-group">
                    <h4 class="filter-group-title">Rentabilidad específica:</h4>
                    <div v-if="_g_UserFeatures.has_active_role" class="pair-container">
                        <div class="select-wrapper" v-for="(iSelect, selectIndex) in third_config[0].componentsConfig" :key="iSelect.component_class">
                            <label :for="iSelect.component_class" class="input-label">{{ iSelect.label_name }}</label>
                            <select 
                                :id="iSelect.component_class"
                                class="vanilla-select"
                                :value="local_array_stocks_filters[selectIndex].value" 
                                @change="_handleSelectChange($event, iSelect.component_class)"
                            >
                                <option 
                                    v-for="option in iSelect.options" 
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-else><FilterNoPermission :link="buy_link" /></div>
                </div>

                 <!-- Variación Anual Group -->
                 <div class="filter-group variacion-anual-group">
                    <h4 class="filter-group-title">Variación Anual:</h4>
                    <div v-if="_g_UserFeatures.has_active_role" class="pair-container">
                         <div class="select-wrapper" v-for="(iSelect, selectIndex) in third_config[6].componentsConfig" :key="iSelect.component_class">
                            <label :for="iSelect.component_class" class="input-label">{{ iSelect.label_name }}</label>
                            <select 
                                :id="iSelect.component_class"
                                class="vanilla-select"
                                :value="local_array_stocks_filters[selectIndex + 5].value" 
                                @change="_handleSelectChange($event, iSelect.component_class)"
                            >
                                 <option 
                                    v-for="option in iSelect.options" 
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-else><FilterNoPermission :link="buy_link" /></div>
                </div>

                 <!-- Número de Onda Group -->
                <div class="filter-group num-onda-group">
                    <h4 class="filter-group-title">Número de onda:</h4>
                    <div v-if="_g_UserFeatures.has_active_role" class="pair-container">
                        <div class="select-wrapper" v-for="(iSelect, selectIndex) in third_config[1].componentsConfig" :key="iSelect.component_class">
                            <label :for="iSelect.component_class" class="input-label">{{ iSelect.label_name }}</label>
                             <select 
                                :id="iSelect.component_class"
                                class="vanilla-select"
                                :value="local_array_stocks_filters[selectIndex + 2].value" 
                                @change="_handleSelectChange($event, iSelect.component_class)"
                            >
                                <option 
                                    v-for="option in iSelect.options" 
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-else><FilterNoPermission :link="buy_link" /></div>
                </div>

                 <!-- CFDs Habilitados Group -->
                 <div class="filter-group cfd-group" v-if="crypto_enabled==false">
                    <h4 class="filter-group-title">CFDs habilitados:</h4>
                     <div v-if="_g_UserFeatures.has_active_role">
                        <div class="select-wrapper" v-if="third_config[5] && third_config[5].componentsConfig.length > 0">
                             <label :for="third_config[5].componentsConfig[0].component_class" class="input-label">{{ third_config[5].componentsConfig[0].label_name }}</label>
                            <select 
                                :id="third_config[5].componentsConfig[0].component_class"
                                class="vanilla-select cfd-select" 
                                :value="local_array_stocks_filters[4].value" 
                                @change="_handleSelectChange($event, third_config[5].componentsConfig[0].component_class)"
                             >
                                 <option 
                                    v-for="option in third_config[5].componentsConfig[0].options" 
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-else><FilterNoPermission :link="buy_link" /></div>
                </div>

            </div>

            <!-- Row Two: Date Filters (Replaced with text inputs) -->
            <div class="filter-row">
                 <!-- Fecha Soporte Group -->
                 <div class="filter-group fecha-soporte-group">
                    <h4 class="filter-group-title">Fecha de soporte:</h4>
                    <div v-if="_g_UserFeatures.has_active_role" class="pair-container">
                        <div class="input-wrapper date-input-wrapper">
                            <label for="third-fecha-soporte-inf" class="input-label">Fecha inicio</label>
                            <input 
                                type="text" 
                                id="third-fecha-soporte-inf" 
                                class="vanilla-input date-text-input"
                                v-model="datepicker_soporte_inferior" 
                                placeholder="dd/mm/yyyy"
                                maxlength="10"
                                @input="_handleDateTextInput($event, 'min_support_date')"
                                @blur="_validateDateInput($event, 'min_support_date')"
                                @keydown="_allowDigitsAndSlash($event)"
                            />
                        </div>
                        <div class="input-wrapper date-input-wrapper">
                            <label for="third-fecha-soporte-sup" class="input-label">Fecha fin</label>
                            <input 
                                type="text" 
                                id="third-fecha-soporte-sup" 
                                class="vanilla-input date-text-input"
                                v-model="datepicker_soporte_superior"
                                placeholder="dd/mm/yyyy"
                                maxlength="10"
                                @input="_handleDateTextInput($event, 'max_support_date')"
                                @blur="_validateDateInput($event, 'max_support_date')"
                                @keydown="_allowDigitsAndSlash($event)"
                             />
                        </div>
                    </div>
                     <div v-else><FilterNoPermission :link="buy_link" /></div>
                </div>

                 <!-- Fecha Estabilizar Group -->
                <div class="filter-group fecha-estabilizar-group">
                    <h4 class="filter-group-title">Fecha para estabilizar:</h4>
                    <div v-if="_g_UserFeatures.has_active_role" class="pair-container">
                        <div class="input-wrapper date-input-wrapper">
                             <label for="third-fecha-estabilizar-inf" class="input-label">Fecha inicio</label>
                             <input 
                                type="text" 
                                id="third-fecha-estabilizar-inf" 
                                class="vanilla-input date-text-input"
                                v-model="datepicker_estabilizar_inferior"
                                placeholder="dd/mm/yyyy"
                                maxlength="10"
                                @input="_handleDateTextInput($event, 'profit_date', 0)"
                                @blur="_validateDateInput($event, 'profit_date', 0)"
                                @keydown="_allowDigitsAndSlash($event)"
                            />
                        </div>
                         <div class="input-wrapper date-input-wrapper">
                             <label for="third-fecha-estabilizar-sup" class="input-label">Fecha fin</label>
                             <input 
                                type="text" 
                                id="third-fecha-estabilizar-sup" 
                                class="vanilla-input date-text-input"
                                v-model="datepicker_estabilizar_superior"
                                placeholder="dd/mm/yyyy"
                                maxlength="10"
                                @input="_handleDateTextInput($event, 'profit_date', 1)"
                                @blur="_validateDateInput($event, 'profit_date', 1)"
                                @keydown="_allowDigitsAndSlash($event)"
                            />
                        </div>
                    </div>
                     <div v-else><FilterNoPermission :link="buy_link" /></div>
                </div>
                
                 <!-- Fecha Máx Histórico Group -->
                 <div class="filter-group fecha-max-hist-group">
                    <h4 class="filter-group-title">Fecha en máximo histórico:</h4>
                     <div v-if="_g_UserFeatures.has_active_role" class="pair-container">
                         <div class="input-wrapper date-input-wrapper">
                            <label for="third-fecha-max-hist-inf" class="input-label">Fecha inicio</label>
                            <input 
                                type="text" 
                                id="third-fecha-max-hist-inf" 
                                class="vanilla-input date-text-input"
                                v-model="datepicker_maxHistorico_inferior"
                                placeholder="dd/mm/yyyy"
                                maxlength="10"
                                @input="_handleDateTextInput($event, 'max_min_bar_date', 0)"
                                @blur="_validateDateInput($event, 'max_min_bar_date', 0)"
                                @keydown="_allowDigitsAndSlash($event)"
                            />
                        </div>
                         <div class="input-wrapper date-input-wrapper">
                            <label for="third-fecha-max-hist-sup" class="input-label">Fecha fin</label>
                             <input 
                                type="text" 
                                id="third-fecha-max-hist-sup" 
                                class="vanilla-input date-text-input"
                                v-model="datepicker_maxHistorico_superior"
                                placeholder="dd/mm/yyyy"
                                maxlength="10"
                                @input="_handleDateTextInput($event, 'max_min_bar_date', 1)"
                                @blur="_validateDateInput($event, 'max_min_bar_date', 1)"
                                @keydown="_allowDigitsAndSlash($event)"
                            />
                        </div>
                    </div>
                     <div v-else><FilterNoPermission :link="buy_link" /></div>
                </div>

            </div>
        </div>
         <div v-else class="loading-placeholder">
            <!-- Cargando filtros... -->
        </div>
    </div>
</template>

<script>
import ThirdConfig from "@/components/stockchart/components/Finder/configs/Third.json";
// import ThirdConfigCripto from "@/components/stockchart/components/Finder/configs/Third_cripto.json"; // Keep if needed
// Remove Datepicker imports
// import Datepicker from 'vuejs-datepicker';
// import {en, es} from 'vuejs-datepicker/dist/locale'
// Remove Select imports
// import SelectOutline from '@/components/helpers/newMaterial/SelectInputFilters.vue';
// import SelectInputFiltersNoNotch from '@/components/helpers/newMaterial/SelectInputFiltersNoNotch.vue';
import { mapMutations, mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import FilterNoPermission from "@/components/stockchart/components/Finder/FilterNoPermission";

export default {
    mixins: [APICaller],
    name: 'ThirdFilterGroup', // Renamed for clarity
    components: {
        // Removed Selects and Datepicker
        FilterNoPermission,
    },
    props: {
        filters_user_json:{type: Object, default: () => ({}) }, // Added default
    },
    data: () => ({
        third_config: ThirdConfig,
        local_array_stocks_filters: [], // Holds formatted values for selects
        finder: null, // Local copy of finder data
        
        // Data properties to hold DD/MM/YYYY format for text inputs
        datepicker_soporte_inferior: "", 
        datepicker_soporte_superior: "",
        datepicker_estabilizar_inferior:"",
        datepicker_estabilizar_superior:"",
        datepicker_maxHistorico_inferior:"",
        datepicker_maxHistorico_superior:"",
        
        buy_link: ""
    }),
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
    },
    created: function() {   
        // Watcher handles initial data loading now
    },

    watch: {        
        // Watcher for global finder changes
         _g_FinderFilters: {
            handler(newFinder) {
                if (newFinder && Object.keys(newFinder).length > 0) {
                    this._getFinderParameters(newFinder); 
                } else {
                    this.local_array_stocks_filters = [];
                    this.finder = null;
                    this._clearAllDateInputs(); // Clear text inputs 
                }
            },
            immediate: true, // Run on component mount
            deep: true // Watch for nested changes
        }
        // Remove individual watchers for old datepicker models
    },

    computed: {
        ...mapGetters(['_g_crypto_enable', '_g_UserFeatures', '_g_FinderFilters']),
         crypto_enabled() {
           return this._g_crypto_enable;
         }
    },
    methods: {
        ...mapMutations(['setFinderFilters']), 

        // --- Date Text Input Handling Methods --- 

        _allowDigitsAndSlash(event) {
            // Allow backspace, delete, tab, escape, enter, home, end, left, right
            if ([46, 8, 9, 27, 13, 35, 36, 37, 39].includes(event.keyCode)) {
                 return;
            }
            // Allow Ctrl+A, Command+A
            if (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) {
                return;
            }
            // Allow Ctrl+C, Command+C
             if (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) {
                 return;
            }
            // Allow Ctrl+V, Command+V (validation on paste might be needed)
             if (event.keyCode === 86 && (event.ctrlKey === true || event.metaKey === true)) { 
                 // Consider adding paste validation later
                 return;
             }
            // Allow Ctrl+X, Command+X
            if (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) {
                 return;
            }
            // Ensure that it is a number or a slash and stop the keypress
            if (!/^[0-9/]$/.test(event.key)) {
                event.preventDefault();
            }
        },

        _handleDateTextInput(event, finderKey, index = null) {
            let value = event.target.value;
            // Remove non-digit/slash characters
            value = value.replace(/[^0-9/]/g, '');

            // Add slashes automatically
            if (value.length === 2 && this._lastValueLength < 2) {
                value += '/';
            } else if (value.length === 5 && this._lastValueLength < 5) {
                 value += '/';
            }
            // Handle backspace removing slash
            if (value.length === 3 && value.endsWith('/') && this._lastValueLength > value.length) {
                value = value.slice(0, 2);
            }
            if (value.length === 6 && value.endsWith('/') && this._lastValueLength > value.length) {
                value = value.slice(0, 5);
            }

            // Limit length
            if (value.length > 10) {
                value = value.slice(0, 10);
            }

            this._lastValueLength = value.length; // Store length for backspace logic
            
             // Update the v-model directly (which triggers watcher if needed)
            this._updateDateInputModel(finderKey, index, value);
            
            // Optional: Immediate validation/update (can be noisy)
            // if (this._isValidDateString(value)) { 
            //    this._updateFinderDate(finderKey, index, value);
            // } 
        },

        _validateDateInput(event, finderKey, index = null) {
            const value = event.target.value;
            if (value === "") { // Allow empty input
                this._updateFinderDate(finderKey, index, "");
                event.target.classList.remove('invalid-date');
                return;
            }
            
            if (this._isValidDateString(value)) {
                this._updateFinderDate(finderKey, index, value);
                 event.target.classList.remove('invalid-date');
            } else {
                 // Handle invalid date - maybe clear it or keep invalid value?
                 // Option 1: Clear the input and finder
                 // this._updateDateInputModel(finderKey, index, "");
                 // this._updateFinderDate(finderKey, index, ""); 
                 // Option 2: Show visual feedback
                 event.target.classList.add('invalid-date');
                  // Optionally prevent updating finder on invalid
                 // this._updateFinderDate(finderKey, index, "INVALID"); // Or some indicator
            }
        },

         _isValidDateString(dateString) {
            if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
                return false; // Basic format check DD/MM/YYYY
            }
            const parts = dateString.split("/");
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const year = parseInt(parts[2], 10);

            if(year < 1900 || year > 2100 || month == 0 || month > 12) {
                 return false;
            }
            const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
            // Adjust for leap years
            if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
                 monthLength[1] = 29;
            }
            return day > 0 && day <= monthLength[month - 1];
        },
        
        // Updates the finder object with the validated DD/MM/YYYY string
        _updateFinderDate(finderKey, index, ddmmyyyy) {
            if (!this.finder) return;
             if (index !== null) {
                this.finder[finderKey] = this.finder[finderKey] || ["", ""];
                if (this.finder[finderKey][index] !== ddmmyyyy) {
                    this.finder[finderKey][index] = ddmmyyyy;
                    this._updateLastSearch2();
                 }
             } else {
                  if (this.finder[finderKey] !== ddmmyyyy) {
                     this.finder[finderKey] = ddmmyyyy;
                     this._updateLastSearch2();
                 }
            }
        },

        // Helper to update the correct data property for the text input v-model
        _updateDateInputModel(finderKey, index, ddmmyyyyValue) {
            switch (finderKey) {
                case 'min_support_date': this.datepicker_soporte_inferior = ddmmyyyyValue; break;
                case 'max_support_date': this.datepicker_soporte_superior = ddmmyyyyValue; break;
                case 'profit_date':
                    if (index === 0) this.datepicker_estabilizar_inferior = ddmmyyyyValue;
                    else if (index === 1) this.datepicker_estabilizar_superior = ddmmyyyyValue;
                    break;
                case 'max_min_bar_date':
                     if (index === 0) this.datepicker_maxHistorico_inferior = ddmmyyyyValue;
                    else if (index === 1) this.datepicker_maxHistorico_superior = ddmmyyyyValue;
                    break;
            }
        },
        
         // Helper to clear all date text input models
        _clearAllDateInputs() {
            this.datepicker_soporte_inferior = "";
            this.datepicker_soporte_superior = "";
            this.datepicker_estabilizar_inferior = "";
            this.datepicker_estabilizar_superior = "";
            this.datepicker_maxHistorico_inferior = "";
            this.datepicker_maxHistorico_superior = "";
        },

        // --- Select Handling (No change needed from previous step) --- 

         _handleSelectChange: function(event, key) {
            const newValue = event.target.value;
             this._getValueFromSelect([newValue, key]);
        },

        _getValueFromSelect: function(value){
            if (!this.finder) return;
            const newValue = value[0];
            const key = value[1]; 
            let changed = true;
            switch (key) {
                case "rentabilidadInferior": this.finder.min_tae_lt = newValue === "< 35" ? "" : parseInt(newValue); break;
                case "rentabilidadSuperior": this.finder.max_tae_lt = newValue === "> 400" ? "" : parseInt(newValue); break;
                case "numOndaInferior":      this.finder.min_ona = newValue === "All" ? "" : parseInt(newValue); break;
                case "numOndaSuperior":      this.finder.max_ona = newValue === "> 25" ? "" : parseInt(newValue); break;
                case "CFDsSelect":           this.finder.extrad_cfds_direction = newValue === "No (Muestra todos los valores)" ? "0" : "1"; break;
                case "varAnualInferior":     this.finder.min_anual_variation = newValue === "< 35" ? "" : parseInt(newValue); break;
                case "varAnualSuperior":     this.finder.max_anual_variation = newValue === "> 400" ? "" : parseInt(newValue); break;
                default:
                    console.warn(`Unknown select key: ${key}`);
                    changed = false;
                    break;
            }
            if (changed) {
                this._dataToNewFormat(); 
                this._updateLastSearch2(); 
            }
        },
        
        // --- Data Formatting and Update --- 

        _dataToNewFormat: function(){ // Formats finder data for local state (selects/dates)
             if (!this.finder) {
                this.local_array_stocks_filters = [];
                 this._clearAllDateInputs();
                return;
            }
            let finder = this.finder;

            // --- Select Values --- 
            const min_tae_lt_value = (finder.min_tae_lt === "" || finder.min_tae_lt === undefined || finder.min_tae_lt === null) ? "< 35" : finder.min_tae_lt.toString();
            const max_tae_lt_value = (finder.max_tae_lt === "" || finder.max_tae_lt === undefined || finder.max_tae_lt === null) ? "> 400" : finder.max_tae_lt.toString();
            const min_onda = (finder.min_ona === "" || finder.min_ona === undefined || finder.min_ona === null) ? "All" : finder.min_ona.toString();
            const max_onda = (finder.max_ona === "" || finder.max_ona === undefined || finder.max_ona === null) ? "> 25" : finder.max_ona.toString();
            const value_cfd = (finder.extrad_cfds_direction == 0 || finder.extrad_cfds_direction === undefined || finder.extrad_cfds_direction === null) ? "No (Muestra todos los valores)" : "Si (Muestra sólo CFDs habilitados)";
            const min_anual_variation_value = (finder.min_anual_variation === "" || finder.min_anual_variation === undefined || finder.min_anual_variation === null) ? "< 35" : finder.min_anual_variation.toString();
            const max_anual_variation_value = (finder.max_anual_variation === "" || finder.max_anual_variation === undefined || finder.max_anual_variation === null) ? "> 400" : finder.max_anual_variation.toString();

            this.local_array_stocks_filters = [
                { name : "R.especifica-li", value: min_tae_lt_value },
                { name : "R.especifica-ls", value: max_tae_lt_value },
                { name : "N.onda-li", value: min_onda },
                { name : "N.onda-ls", value: max_onda },
                { name : "CFD", value: value_cfd },
                { name : "V.anual-li", value: min_anual_variation_value },
                { name : "V.anual-ls", value: max_anual_variation_value },
            ];

            // --- Date Values (Populate text input v-models directly) --- 
            finder.profit_date = finder.profit_date || ["", ""];
            finder.max_min_bar_date = finder.max_min_bar_date || ["", ""];

            // Use empty string for the old default date
            const defaultDate = "01/01/1970";
            this.datepicker_soporte_inferior = (finder.min_support_date === defaultDate) ? "" : finder.min_support_date || "";
            this.datepicker_soporte_superior = (finder.max_support_date === defaultDate) ? "" : finder.max_support_date || "";
            this.datepicker_estabilizar_inferior = (finder.profit_date[0] === defaultDate) ? "" : finder.profit_date[0] || "";
            this.datepicker_estabilizar_superior = (finder.profit_date[1] === defaultDate) ? "" : finder.profit_date[1] || "";
            this.datepicker_maxHistorico_inferior = (finder.max_min_bar_date[0] === defaultDate) ? "" : finder.max_min_bar_date[0] || "";
            this.datepicker_maxHistorico_superior = (finder.max_min_bar_date[1] === defaultDate) ? "" : finder.max_min_bar_date[1] || "";
        },
        
        _getFinderParameters: function(finderData){
             if (!finderData) return;
            this.finder = JSON.parse(JSON.stringify(finderData)); // Deep copy
            this._dataToNewFormat(); // Format data for selects and date text inputs
        },

        _updateLastSearch2: function(){ // Update global state
            if (!this.finder) return;
             // Ensure date arrays have two elements before sending
             this.finder.profit_date = this.finder.profit_date || ["", ""];
             this.finder.max_min_bar_date = this.finder.max_min_bar_date || ["", ""];
             if (this.finder.profit_date.length < 2) this.finder.profit_date.push("");
             if (this.finder.max_min_bar_date.length < 2) this.finder.max_min_bar_date.push("");
             
            this.setFinderFilters(JSON.parse(JSON.stringify(this.finder)));
            this.$emit("updateLastSearch");
        },
    },
}
</script>

<style lang="scss" scoped>
/* Styles inherited from Finder-Base.vue unscoped block */

/* Add any styles SPECIFIC to Third.vue here */

.third-filters-container {
    /* Container specific adjustments if needed */
}

.filter-row {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping within a row */
    gap: 20px; /* Space between groups in the same row */
    margin-bottom: 20px; /* Space between rows */
}

.filter-row:last-child {
    margin-bottom: 0;
}

/* Adjust flex basis for groups if needed */
.filter-group {
     flex: 1 1 200px; /* Example: allow growing/shrinking with a base width */
     min-width: 180px;
}

/* Make CFD select potentially wider */
.cfd-select {
    /* Inherits width: 100% */
}

/* Style for text date inputs */
.date-text-input {
    /* Inherits .vanilla-input styles */
}

.date-text-input.invalid-date {
    border-color: var(--color-error, #B00020);
    box-shadow: 0 0 0 2px rgba(176, 0, 32, 0.2);
}

/* Wrapper for date text input */
.date-input-wrapper {
    /* Inherits .input-wrapper styles */
}


/* Remove old vuejs-datepicker styles */
/*
.datepick ::v-deep .datepicker-input { ... }
.datepick ::v-deep .vdp-datepicker__calendar { ... }
*/

</style>