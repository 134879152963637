<template>
<div>
  <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user && _g_UserFeatures.has_active_role" class="stocks-container">
    <p class="stocks-info-query" title="Número de tickers">
      <span class="counter">{{ counter_stocks() }}</span><span class="tickers">tickers</span>
      <span><div style="display: grid; top: 20px;"><span class="material-icons-outlined" style="margin-top: 14px;" @click="_previousTicker()">arrow_drop_up</span><span class="material-icons-outlined" @click="_nextTicker()">arrow_drop_down</span></div></span>
    </p>
    <p v-if="order_class" class="stocks-order-query" @click="_changeOrderType('open')" title="Ordenar listado">
      <span class="material-icons-outlined ekers-event-click">{{ icon_sort }}</span>
      <span class="key">{{order_type}}</span>
      <span class="material-icons-outlined ekers-event-click">{{ order_by_icon }}</span>
    </p>
    <p v-else class="stocks-order-query" @click="_changeOrderType('close')" title="Ordenar listado">
      <span class="material-icons-outlined ekers-event-click">{{ icon_sort }}</span>
      <span class="key">{{order_type}}</span>
      <span class="material-icons-outlined ekers-event-click">{{ order_by_icon }}</span>
    </p>
    <div v-if="select_order_type" class="order-menu">
      <div v-for="(item, index) in preferences_selected_to_order" :key="index">
        <div class="div-text-order" @click="_orderedStock(item.field, 'asc', item.name[0], item.header)">
          <span class="order-text">{{item.name[0]}}</span>
        </div>
        <div class="div-text-order" @click="_orderedStock(item.field, 'desc', item.name[1], item.header)">
          <span class="order-text">{{item.name[1]}}</span>
        </div>
      </div>
    </div>

    <ul id="my-list" class="mdc-list" role="listbox" v-if="not_ordered">
      <div v-if="_g_User.is_pre_eureker_user" class="pre-eurekers-stocks-info">Usted dispone de <br><span class="pre-eurekers-number">{{pre_eurekers_tries}}</span><br> consumibles para visualizar los valores.</div>
      <div v-if="this.stocks != undefined && this.stocks.length != 0">
        <li class="mdc-list-item" :class="{'mdc-list-item--selected' : stock.ticker == selected_ticker.ticker}" role="option" v-for="(stock, index) in stocks" :key="index">
          <span class="mdc-list-item__ripple"></span>
          <span v-if="_g_User.is_pre_eureker_user && stock.nom == '---'" class="mdc-list-item__text typo-body-2" 
          @click="askPreEurekersTickerInfo(stock.ticker)">
            <span class="material-symbols-outlined">visibility</span>
            <div style="visibility:hidden; height:0;">{{stock.ticker}}</div>
          </span>
          <span v-else-if="_g_User.roles[21] || _g_User.roles[20]" class="mdc-list-item__text typo-body-2"
          :title="'Ticker: ' + stock.ticker 
          + title_break_line 
          + 'Pais: ' + stock.country
          + title_break_line 
          + 'Índice: ' + stock.index_valor
          + title_break_line 
          + 'Divisa: ' + stock.divisa
          + title_break_line 
          + 'Sector: ' + stock.sector + ' | ' + stock.industria
          + title_break_line
          + 'Tipo de entrada: ' + stock.entry_type_name
          + title_break_line
          + title_break_line
          + 'CFD: Su plan actual no tiene acceso al CFD'
          + title_break_line
          + 'Margen CFD: Su plan actual no tiene acceso al Margen CFD'
          ">{{ stock.ticker }}</span>
          <span v-else class="mdc-list-item__text typo-body-2"
          :title="'Ticker: ' + stock.ticker 
          + title_break_line 
          + 'Pais: ' + stock.country
          + title_break_line 
          + 'Índice: ' + stock.index_valor
          + title_break_line 
          + 'Divisa: ' + stock.divisa
          + title_break_line 
          + 'Sector: ' + stock.sector + ' | ' + stock.industria
          + title_break_line
          + 'Tipo de entrada: ' + stock.entry_type_name
          + title_break_line
          + title_break_line
          + 'CFD: ' + _checkCFD(stock)
          + title_break_line
          + 'Margen CFD: ' + stock.cfd_margin + '%'
          ">{{ stock.ticker }}</span>
          <span v-if="stock.open_positions_portfolio_name && !(_g_User.roles[21] || _g_User.roles[20])" title="Valor en posiciones abiertas"
            class="alarm push mdc-list-item__meta material-icons"
            aria-hidden="true">
            work
          </span>
          <span v-else-if="stock.waitings_portfolio_name == undefined && !(_g_User.roles[21] || _g_User.roles[20])" title="Añadir a cartera"
            data-action="addWaiting"
            class="alarm push mdc-list-item__meta material-icons"
            aria-hidden="true">more_time</span>
          <span v-else-if="stock.waitings_portfolio_name != undefined && !(_g_User.roles[21] || _g_User.roles[20])" title="Valor en espera"
            data-action="addWaiting"
            class="alarm push mdc-list-item__meta material-icons alarm-selected stock-icon-on-bg"
            aria-hidden="true">schedule</span>       
          <span v-if="stock.folder_name && stock.folder_name.length == 0" title="Añadir a favoritos"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star_border</span>
          <span v-else-if="stock.folder_name && stock.folder_name.length == 1 && workspaces_names.includes(stock.folder_name[0])" title="Añadir a favoritos"
              :style="{color: _getColorByWorkspace(stock.folder_name[0])}"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star</span>
          <span v-else-if="stock.folder_name && stock.folder_name.length > 1" title="Añadir a favoritos"
              style="color: white"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons stock-icon-on-bg"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star</span>
          <span v-else title="Añadir a favoritos"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star_border</span>
          <!--<span data-action="addPortfolio" class="folder mdc-list-item__meta material-icons" aria-hidden="true">folder_open</span>
          <span data-action="addNotification" class="notifications mdc-list-item__meta material-icons" aria-hidden="true">notifications_none</span>-->
        </li>
      </div>
      <div v-else class="stocks-query-not-found">
        <p>
          No existen tickers con esos criterios de búsqueda.
        </p>
      </div>
    </ul>
    <ul id="my-list" class="mdc-list" role="listbox" v-else>
      <div v-if="_g_User.is_pre_eureker_user" class="pre-eurekers-stocks-info">Usted dispone de <br><span class="pre-eurekers-number">{{pre_eurekers_tries}}</span><br> consumibles para visualizar los valores.</div>
      <div v-if="this.stocks != undefined && this.stocks.length != 0">
        <li class="mdc-list-item" :class="{'mdc-list-item--selected' : stock.ticker == selected_ticker.ticker}" role="option" v-for="(stock, index) in ordered_stocks" :key="ordered_stocks.length - index">
          <span class="mdc-list-item__ripple"></span>
          <span v-if="_g_User.is_pre_eureker_user && stock.nom == '---'" class="mdc-list-item__text typo-body-2" 
          @click="askPreEurekersTickerInfo(stock.ticker)">
            <span class="material-symbols-outlined">visibility</span>
            <div style="visibility:hidden; height:0;">{{stock.ticker}}</div>
          </span>
          <span v-else-if="_g_User.roles[21] || _g_User.roles[20]" class="mdc-list-item__text typo-body-2"
          :title="'Ticker: ' + stock.ticker 
          + title_break_line 
          + 'Pais: ' + stock.country
          + title_break_line 
          + 'Índice: ' + stock.index_valor
          + title_break_line 
          + 'Divisa: ' + stock.divisa
          + title_break_line 
          + 'Sector: ' + stock.sector + ' | ' + stock.industria
          + title_break_line
          + 'Tipo de entrada: ' + stock.entry_type_name
          + title_break_line
          + title_break_line
          + 'CFD: Su plan actual no tiene acceso al CFD'
          + title_break_line
          + 'Margen CFD: Su plan actual no tiene acceso al Margen CFD'
          ">{{ stock.ticker }}</span>
          <span v-else class="mdc-list-item__text typo-body-2"
          :title="'Ticker: ' + stock.ticker 
          + title_break_line 
          + 'Pais: ' + stock.country
          + title_break_line 
          + 'Índice: ' + stock.index_valor
          + title_break_line 
          + 'Divisa: ' + stock.divisa
          + title_break_line 
          + 'Sector: ' + stock.sector + ' | ' + stock.industria
          + title_break_line
          + 'Tipo de entrada: ' + stock.entry_type_name
          + title_break_line
          + title_break_line
          + 'CFD: ' + _checkCFD(stock)
          + title_break_line
          + 'Margen CFD: ' + stock.cfd_margin + '%'
          ">{{ stock.ticker }}</span>
          <span v-if="stock.open_positions_portfolio_name && !(_g_User.roles[21] || _g_User.roles[20])" title="Valor en posiciones abiertas"
            class="alarm push mdc-list-item__meta material-icons"
            aria-hidden="true">
            work
          </span>
          <span v-else-if="stock.waitings_portfolio_name == undefined && !(_g_User.roles[21] || _g_User.roles[20])" title="Añadir a cartera"
            data-action="addWaiting"
            class="alarm push mdc-list-item__meta material-icons"
            aria-hidden="true">more_time</span>
          <span v-else-if="stock.waitings_portfolio_name != undefined && !(_g_User.roles[21] || _g_User.roles[20])" title="Valor en espera"
            style="color: black;"
            data-action="addWaiting"
            class="alarm push mdc-list-item__meta material-icons"
            aria-hidden="true">schedule</span>        
          <span v-if="stock.folder_name && stock.folder_name.length == 0" title="Añadir a favoritos"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star_border</span>
          <span v-else-if="stock.folder_name && stock.folder_name.length == 1 && workspaces_names.includes(stock.folder_name[0])" title="Añadir a favoritos"
              :style="{color: _getColorByWorkspace(stock.folder_name[0])}"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star</span>
          <span v-else-if="stock.folder_name && stock.folder_name.length > 1" title="Añadir a favoritos"
              style="color: white"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons stock-icon-on-bg"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star</span>
          <span v-else title="Añadir a favoritos"
              :id="'icon'+index"
              data-action="addFavorites"
              class="favorite mdc-list-item__meta material-icons"
              :class="{'active' : stock.ticker == 'D', 'star-margin' : _g_User.roles[21] || _g_User.roles[20]}"
              aria-hidden="true">star_border</span>
        </li>
      </div>
      <div v-else class="stocks-query-not-found">
        <p>
          No existen tickers con esos criterios de búsqueda.
        </p>
      </div>
    </ul>
    <div>
      <div v-if="selected_ticker && selected_ticker!=''">
        <Workspaces v-if="stocks != ''"
            ref="WorkspaceRef"
            :stocks="stocks"
            :stock="selected_ticker"
            :show_workspace="show_workspace"
            @_closeFavoriteWorkspacesWindow="_closeFavoriteWorkspacesWindow"
            @_favorite_added="_favorite_added"
            @_favorite_removed="_favorite_removed"
            @workspace_edited="_initWorkspaces">
        </Workspaces>

        <WaitingValues v-if="stocks != ''"
            ref="waiting"
            :stocks="stocks"
            :stock="selected_ticker"
            :show_waiting="show_waiting"
            @_closeWaitingValueWindow="_closeWaitingValueWindow"
            @waiting-added="getStocks">
        </WaitingValues>
      </div>
    </div>
  </div>

  <div v-else-if="_g_User.is_only_authenticated_user" class="stocks-container">
    <p class="stocks-info-query">
      <span class="counter">{{ counter_stocks() }}</span><span class="tickers">tickers</span>
    </p>
    <p class="stocks-order-query" style="cursor: not-allowed">
      <span class="material-icons-outlined ekers-event-click" style="cursor: not-allowed">{{ icon_sort }}</span>
      <span class="key">{{order_type}}</span>
      <span class="material-icons-outlined ekers-event-click" style="cursor: not-allowed">{{ order_by_icon }}</span>
    </p>

    <ul id="my-list" class="mdc-list blured" role="listbox">
      <li class="mdc-list-item" role="option" style="cursor: not-allowed" v-for="(stock, index) in stock_anonymous" :key="index">
        <span class="mdc-list-item" style="margin-left: -30px;"></span>
        <span class="mdc-list-item__text typo-body-2">{{ stock }}</span>
        <span class="alarm push mdc-list-item__meta material-icons" aria-hidden="true">schedule</span>
        <span
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock == 'D'}"
            aria-hidden="true">{{ iconFavorite(stock) }}</span>
        <!--<span data-action="addPortfolio" class="folder mdc-list-item__meta material-icons" aria-hidden="true">folder_open</span>
        <span data-action="addNotification" class="notifications mdc-list-item__meta material-icons" aria-hidden="true">notifications_none</span>-->
      </li>
    </ul>
    <div style="position:absolute; top: 100px; text-align: center; padding: 40px; cursor: not-allowed">
      <p><strong>Su plan no tiene acceso al listado de tickers. Pulse aquí para <a class="not-allowed-stocks" href="/app/store">cambiar de plan</a>.</strong></p>
    </div>
  </div>

  <div v-else class="stocks-container">
    <p class="stocks-info-query">
      <span class="counter"> ??? </span><span class="tickers">tickers</span>
    </p>
    <p class="stocks-order-query" style="cursor: not-allowed">
      <span class="material-icons-outlined ekers-event-click" style="cursor: not-allowed">{{ icon_sort }}</span>
      <span class="key">{{order_type}}</span>
      <span class="material-icons-outlined ekers-event-click" style="cursor: not-allowed">{{ order_by_icon }}</span>
    </p>

    <ul id="my-list" class="mdc-list blured" role="listbox">
      <li class="mdc-list-item" role="option" style="cursor: not-allowed" v-for="(stock, index) in stock_anonymous" :key="index">
        <span class="mdc-list-item" style="margin-left: -30px;"></span>
        <span class="mdc-list-item__text typo-body-2">{{ stock }}</span>
        <span class="alarm push mdc-list-item__meta material-icons" aria-hidden="true">schedule</span>
        <span
            class="favorite mdc-list-item__meta material-icons" 
            :class="{'active' : stock == 'D'}"
            aria-hidden="true">{{ iconFavorite(stock) }}</span>
        <!--<span data-action="addPortfolio" class="folder mdc-list-item__meta material-icons" aria-hidden="true">folder_open</span>
        <span data-action="addNotification" class="notifications mdc-list-item__meta material-icons" aria-hidden="true">notifications_none</span>-->
      </li>
    </ul>
    <div style="position:absolute; top: 100px; text-align: center; padding: 40px; cursor: not-allowed">
      <p><strong>Si quieres acceder al listado de valores Eurekers inicia sesión.</strong></p>
    </div>
  </div>
</div>
</template>



<script>
import Workspaces from '@/components/stockchart/components/Workspaces.vue';
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";
import WaitingValues from '@/components/stockchart/components/WaitingValuesAdd.vue';


import { mapMutations, mapGetters } from "vuex";

import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';

export default {
  mixins: [APICaller, GetStocks],
  components: {
      Workspaces,
      WaitingValues,
      
  },
  name: 'Stocks',
  data: () => ({
    stocks: [],
    aux: [],
    ordered_stocks: [],
    selected_ticker: '',
    show_workspace: false,
    show_waiting: false,
    order_by: false,
    order_class: true,
    order_type: 'Nombre A-Z',
    title_break_line: "\n",
    position: [],
    select_order_type: false,
    not_ordered: true,
    stock_anonymous:[],
    workspaces: [],
    workspaces_names: [],
    workspaces_colors: [],
    color: "",
    modeOfStocks:'',
    last_ticker_selected: '',
    preferences: [],
    order_by_preferences: [
      {id: "Montante", name:["Montante ASC", "Montante DESC"], field:"amount", header: "Montante"},
      {id: "Ticker", name:["Nombre A-Z", "Nombre Z-A"], field: "ticker", header: "Ticker"},
      {id: "Tipo de entrada", name:["Tipo de entrada A-Z", "Tipo de entrada Z-A"], field:"entry_type_name", header: "T. entrada"},
      {id: "Acciones", name:["Acciones ASC", "Acciones DESC"], field:"instrument_number", header: "Acciones"},
      {id: "Entrada", name:["Entrada ASC", "Entrada DESC"], field:"real_entry", header: "Entrada"},
      {id: "Stop", name:["Stop ASC", "Stop DESC"], field:"out_price", header: "Stop"},
      {id: "Soporte", name:["Soporte ASC", "Soporte DESC"], field: "soport", header: "Soporte"},
      {id: "Fecha de soporte", name:["Fecha soporte ASC", "Fecha soporte DESC"], field: "data_soport", header: "Fecha sop."},
      {id: "Valor actual", name:["Valor actual ASC", "Valor actual DESC"], field: "valor_actual", header: "Valor act."},
      {id: "Divisa", name:["Divisa ASC", "Divisa DESC"], field: "cotitzacio_divisa", header: "Divisa"},
      {id: "Rentabilidad", name:["Rentabilidad ASC", "Rentabilidad DESC"], field: "tae_lt", header: "Rentab."},
      {id: "Distancia de entrada", name:["Dist. entrada ASC", "Dist. entrada DESC"], field: "entry_distance", header: "Dist. Ent."},
      {id: "Resistencia", name:["Resistencia ASC", "Resistencia DESC"], field: "resistance", header:"Resist."},
      {id: "Variación anual", name:["Var. anual ASC", "Var. anual DESC"], field: "anual_var", header: "Var. anual"},
      {id: "Número de onda", name:["Núm. de onda ASC", "Núm. de onda DESC"], field: "wave_number", header: "Nº onda"},
      {id: "Siguiente LT", name:["Siguiente LT ASC", "Siguiente LT DESC"], field: "next_entry_lt", header: "Sig. LT"},
      {id: "Primera fecha", name:["Primera fecha ASC", "Primera fecha DESC"], field: "fecha_primera_barra", header: "1ª fecha"},
      {id: "Inversión/Riesgo", name:["Inver./Riesgo ASC", "Inver./Riesgo DESC"], field: "risk_reward", header:"Inv/Riesgo"},
      {id: "Compatibilidad", name:["Compatib. ASC", "Compatib. DESC"], field: "user_platinum_compatibility", header: "Compat."},
      {id: "Comisión de entrada", name:["Comisión ent. ASC", "Comisión ent. DESC"], field: "open_fee", header: "Comisión ent."},
      {id: "Beneficio", name:["Beneficio ASC", "Beneficio DESC"], field: "benefit", header: "Beneficio"},
      {id: "Beneficio asegurado", name:["Beneficio ase. ASC", "Beneficio ase. DESC"], field: "insured_benefit", header: "Benef. Aseg."},
      {id: "Fecha de entrada", name:["Fecha entrada ASC", "Fecha entrada DESC"], field: "in_date", header: "F. entrada"},
    ],
    preferences_selected_to_order: [],
    pre_eurekers_tries: 0,
  }),
  mounted() {
    this._changeModeToStockMarket();    
    this.initMaterialComponent();
    this._getPreferences();

    var mode_waiting = setInterval(() => {
      if(this.modeOfStocks != ''){
        if(this._g_IsUserLogged){
          this.getStocks(() => {
            // Signal that stocks are loaded
            this.$emit('stocks-loaded');
          });
          clearInterval(mode_waiting);
        }
      }
    }, 10);    
    
    this._createAnonymousStock();
    var url = window.location.pathname;
    this._initWorkspaces();

    var stock_waiting = setInterval(()=>{
      if(this.stocks && this.stocks.length != 0){
        this._cloneStockList();
        clearInterval(stock_waiting);
      }
    },200)

    if(this._g_order_by != undefined && this._g_order_by.length != 0){
      var waiting_for_stock = setInterval(()=>{
        if(this.stocks && this.stocks.length != 0){
          this._checkWhatPreferenceIsOrdered()
          clearInterval(waiting_for_stock)
        }
      }, 200)
    }
  },
  created: function() {
    window.addEventListener('keyup', (e) => {
      if (e.key == 'ArrowDown') {
        this._nextTicker();
      }else if(e.key == "ArrowUp"){
        this._previousTicker();
      }
    });
    this.initPreEurekersRemainingTickers();
  },
  watch:{ 
    _g_stocks: {
      handler: function(newVal, oldVal){
        if(newVal != oldVal){
          this.stocks = newVal;
          let order_preferences_values = this._getOrderParameters();
          this.ordered_stocks = newVal;
          this._orderedStock(order_preferences_values.field, order_preferences_values.type, order_preferences_values.name, order_preferences_values.header);
        }
      },
      deep: true
    },

    _g_SelectedTicker: {
      handler: function() {
        this.setSelectedActiveTicker(this._g_SelectedTicker);
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({stock_type:'_g_GetTypeStocks'}),
    ...mapGetters({_g_stocks:'_g_Stocks'}),
    ...mapGetters({filters:'_g_ShowStockFinderFilter'}),
    ...mapGetters(["_g_IsUserLogged", "_g_SelectedTicker", "_g_is_loading_stocks","_g_User"]),
    ...mapGetters(["_g_order_by"]),
    ...mapGetters(["_g_UserFeatures"]),
    
    order_by_icon: function() {
      return (this.order_class) ? 'expand_more' : 'expand_less';
    },
    icon_sort: function() {
      return (this.order_by) ? 'sort' : 'sort';
    }
    
  },
  methods: {
    ...mapMutations(['setStockValue']),
    ...mapMutations(['setMenuSelectionOut']),
    ...mapMutations(["setOrderBy"]),

    _checkCFD: function(stock){
      let cfd_habilitado = "No";
      if(stock.activat_dif == 0) {
        cfd_habilitado = "No";
      } else if(stock.activat_dif == 1) {
        cfd_habilitado = "Alcista";
      } else {
        cfd_habilitado = "Alcista y bajista";
      }

      return cfd_habilitado;
    },

    _getPreferences: function() {
      if(!this._g_User.is_only_authenticated_user){
        let success = response => {
          this.preferences = response.data;
          this._getPortfolioPreferences();
          //this.tools = response.data;
        }
        
        let url = '/api/v1/stock-finder/preferences'
  
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('082', 'Error cargando valores.');
  
        this._getAPICall(url, successHandler, failureHandler);
      }
    },

    _favorite_added: function(stock) {
      // Save the currently selected ticker
      const currentSelectedTicker = this.selected_ticker;
      
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].ticker == stock.ticker) {
          stock.favorites = true;
          this.stocks[i].favorites = true;
          
          // Also update in ordered_stocks if present
          if (this.ordered_stocks && this.ordered_stocks.length) {
            for (var j = 0; j < this.ordered_stocks.length; j++) {
              if (this.ordered_stocks[j].ticker == stock.ticker) {
                this.ordered_stocks[j].favorites = true;
              }
            }
          }
          
          // Replace only after all props are updated to ensure reactivity
          this.stocks.splice(i, 1, {...stock});
        }
      }
      
      // No need to call _initWorkspaces() which would cause a reload
      // Instead, just make sure the selected ticker stays the same
      if (currentSelectedTicker) {
        this.setSelectedActiveTicker(currentSelectedTicker);
      }
    },

    _favorite_removed: function(stock) {
      // Save the currently selected ticker
      const currentSelectedTicker = this.selected_ticker;
      
      for (var i = 0; i < this.stocks.length; i++) {
        if (this.stocks[i].ticker == stock.ticker) {
          // Only set favorites=false if there are no more workspaces (folders)
          // containing this stock (folder_name length is 0)
          if (!stock.folder_name || stock.folder_name.length === 0) {
            stock.favorites = false;
            this.stocks[i].favorites = false;
            
            // Also update in ordered_stocks if present
            if (this.ordered_stocks && this.ordered_stocks.length) {
              for (var j = 0; j < this.ordered_stocks.length; j++) {
                if (this.ordered_stocks[j].ticker == stock.ticker) {
                  this.ordered_stocks[j].favorites = false;
                }
              }
            }
          }
          else {
            // If stock still has favorites (belongs to folders), keep favorites=true
            stock.favorites = true;
            this.stocks[i].favorites = true;
            
            // Also update in ordered_stocks if present
            if (this.ordered_stocks && this.ordered_stocks.length) {
              for (var j = 0; j < this.ordered_stocks.length; j++) {
                if (this.ordered_stocks[j].ticker == stock.ticker) {
                  this.ordered_stocks[j].favorites = true;
                }
              }
            }
          }
          
          // Replace for reactivity
          this.stocks.splice(i, 1, {...stock});
        }
      }
      
      // No need to call _initWorkspaces() which would cause a reload
      // Instead, just make sure the selected ticker stays the same
      if (currentSelectedTicker) {
        this.setSelectedActiveTicker(currentSelectedTicker);
      }
    },

    _changeModeToStockMarket: function(){
        let success = (response) => {
          this.modeOfStocks = response;
        };

        let url = "/api/v1/stock-finder/change-search-mode/stock-market";

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('083', 'Error cambiando modo.');

        this._getAPICall(url, successHandler, failureHandler);

    },

    _previousTicker: function(){
      var index = 0;
      var flag = false;
      if(this.not_ordered){
        this.stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index != 0){
          if(this.stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.stocks[index - 1];
          this.setSelectedActiveTicker2(this.stocks[index - 1]);
        }
      }
      else{
        this.ordered_stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index != 0){
          if(this.ordered_stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.ordered_stocks[index - 1];
          this.setSelectedActiveTicker2(this.ordered_stocks[index - 1]);
        }
      }
    },

    _nextTicker: function(){
      var index = 0;
      var flag = false;
      if(this.not_ordered){
        this.stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index < this.stocks.length - 1){
          if(this.stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.stocks[index + 1];
          this.setSelectedActiveTicker2(this.stocks[index + 1]);
        }
      }
      else{
        this.ordered_stocks.forEach(element =>{
          if(element.ticker == this._g_SelectedTicker.ticker){
            flag = true;
            return true;
          }
          if(!flag){
            index++;
          }
        });
        if(index < this.stocks.length - 1){
          if(this.ordered_stocks[index + 1] == this.last_ticker_selected){
            index++;     
          }
          this.last_ticker_selected = this.ordered_stocks[index + 1];
          this.setSelectedActiveTicker2(this.ordered_stocks[index + 1]);
        }
      }
    },

    _createAnonymousStock: function(){
      for (var i = 0; i < 36; i++){
        let word = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
        this.stock_anonymous.push(word.toUpperCase());
      }
    },
    counter_stocks() {
      if(this._g_is_loading_stocks == false){
        return this.stocks.length;
      } else if(this.stocks && this.stocks.length == 0){
        return 0;
      } else{
        return "...";
      }
    },

    initMaterialComponent() {
      const list = new MDCList(document.getElementById('my-list'));
      const adapter = list.getDefaultFoundation().adapter;
      this.counter = adapter.getListItemCount();

      // set properties
      list.vertical = true;
      list.wrapFocus = true;
      list.hasTypeahead = true;
      list.singleSelection = true;
      list.listElements.map((listItemEl) => new MDCRipple(listItemEl));
      if(this._g_IsUserLogged){
        list.listen('MDCList:action', (event) => {
          if(this.not_ordered){
            this.setSelectedActiveTicker2(this.stocks[event.detail.index]);
          }else{
            this.setSelectedActiveTicker2(this.ordered_stocks[event.detail.index]);
          }
        })
      }
      if(this._g_IsUserLogged){
        document.getElementById('my-list').addEventListener('click', (e) => {
            if (e.target.dataset.action != undefined) {
                this.addStockAction(e.target.dataset.action);

                if (e.target.dataset.action === 'addFavorites' && this.show_workspace == false) {
                  var posx = e.clientX;	
                  var posy = e.clientY;
                  this.show_workspace = true;
                  /*var position = function getPos(e) {	
                      for (var lx=0, ly=0;	
                        e.target != null;	
                        lx += e.target.offsetLeft, ly += e.target.offsetTop, e.target = e.target.offsetParent);	
                        return {x: lx,y: ly};	
                    }*/
                    /*this.position = {x:posx, y:posy};*/	
                    /*this.setPositionFavoriteWorkspace({name:"x", value:this.posx}, {name:"y", value:this.posy});*/	
                }

                if (e.target.dataset.action === 'addWaiting' && this.show_waiting == false) {
                  var posx_waiting = e.clientX;	
                  var posy__waiting = e.clientY;
                  this.show_waiting = true;
                  /*var position = function getPos(e) {	
                      for (var lx=0, ly=0;	
                        e.target != null;	
                        lx += e.target.offsetLeft, ly += e.target.offsetTop, e.target = e.target.offsetParent);	
                        return {x: lx,y: ly};	
                    }*/
                    /*this.position = {x:posx, y:posy};*/	
                    /*this.setPositionFavoriteWorkspace({name:"x", value:this.posx}, {name:"y", value:this.posy});*/	
                }
            }
        }, false);

        // set keydown event
      //   document.getElementById('my-list').addEventListener('keydown', () => {
      //     list.listElements[adapter.getFocusedElementIndex()].click();
      //     let ticker = adapter.getPrimaryTextAtIndex(adapter.getFocusedElementIndex());

      //     this.setSelectedActiveTicker(ticker);
      //   }, false);
      }
    },
    addStockAction(action) {
        if (action === 'addFavorites') { this._manageEventFavorite();}
    },

    _manageEventFavorite() {
        // mostrar el componente que toca como en el graficador dinamico
    },

    ...mapMutations(['setSelectedTicker']),
    setSelectedActiveTicker(ticker) { 
      this.setSelectedTicker(ticker);
      this.selected_ticker = ticker;
      
    },

    setSelectedActiveTicker2(ticker){
      this.setSelectedTicker(ticker);
      this.selected_ticker = ticker;
    },

    askPreEurekersTickerInfo(id) {
      let params = new URLSearchParams();
      params.append('ticker_id', id);
      params.append('uid', this._g_User.uid);

      let success= response => {
        if (response.data == true) {
          this.getStocks();
          this.getPreEurekersRemainingTickers();
        }
        else {
          this.$vueOnToast.pop('error', 'No te quedan visualizaciones disponibles');
        }
      };

      let url = '/usuarios/add-requested-ticker';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('278', 'Error agregando ticker.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    initPreEurekersRemainingTickers() {
      let success = response => {
        this.pre_eurekers_tries = response.data;
      };
      
      let url = '/usuarios/get-remaining-ticker-requests';
            
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('084', 'Error cargando visualizaciones disponibles.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    getPreEurekersRemainingTickers() {
      let success = response => {
        this.pre_eurekers_tries = response.data;
      };
      
      let url = '/usuarios/get-remaining-ticker-requests';
            
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('085', 'Error cargando visualizaciones disponibles.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    iconFavorite(stock){
      // If the stock is not marked as favorite or doesn't have folder_name, show star_border
      if (!stock.favorites || !stock.folder_name) {
        return 'star_border';
      }
      
      // If the stock has multiple favorites (folders), return 'star'
      // The style will be handled by the template with different colors
      return 'star';
    },

    _initWorkspaces: function() {
      if(!this._g_User.is_only_authenticated_user){
        let success = response => {
          // Clear arrays first to prevent duplicates
          this.workspaces = [];
          this.workspaces_names = [];
          this.workspaces_colors = [];
          
          if (response.data == [] || response.data.folders == undefined) {
            this.workspaces = [];
          }
          else {
            for (let index = 0; index < response.data.folders.length; index++) {
              const name = response.data.folders[index][0];
              const color = response.data.folders[index][1];
              
              this.workspaces.push({
                name: name,
                color: color,
                isFavorite: false,
              });
              this.workspaces_names.push(name);
              this.workspaces_colors.push(color);
            }
          }
          
          // Get stocks again if it's not the initial load but a workspace update
          if (this.initialized) {
            // Give time for the backend to update its workspace data
            setTimeout(() => {
              this.getStocks(() => {
                // Force re-render by triggering a refresh after stocks are loaded
                this._refreshStockWorkspaceColors();
              });
            }, 100);
          } else {
            this.initialized = true;
            // Just refresh colors for the initial load
            this._refreshStockWorkspaceColors();
          }
        };
  
        let url = '/api/v1/stock-finder/favorite-folder/list';
              
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('086', 'Error cargando listas de favoritos.');
  
        this._getAPICall(url, successHandler, failureHandler);
      }            
    },

    _refreshStockWorkspaceColors: function() {
      // Create a shallow copy of each stock to trigger reactivity
      if (this.stocks && this.stocks.length) {
        // Re-map folder names to colors based on current workspaces
        this.stocks = this.stocks.map(stock => {
          // Create a new reference to trigger reactivity
          const newStock = {...stock};
          return newStock;
        });
        
        // Also update ordered stocks if they exist
        if (this.ordered_stocks && this.ordered_stocks.length) {
          this.ordered_stocks = this.ordered_stocks.map(stock => ({...stock}));
        }
      }
    },

    _getColorByWorkspace(workspace){
      var index = 0;
      if(this.workspaces_names.includes(workspace)){
        index = this.workspaces_names.indexOf(workspace);
      }
      return this.workspaces_colors[index];
    },

    iconSchedule(stock){
      return (stock.waitings_portfolio_name != undefined) ? 'schedule' : 'more_time';  
    },

    orderBy() {
      this.order_by = !this.order_by;
    },

    _cloneStockList: function(){
      this.ordered_stocks = [];
      for (let i = 0; i < this.stocks.length; i++) {
        this.ordered_stocks[i] = this.stocks[i];
      }
    },

    _closeFavoriteWorkspacesWindow() {
      this.show_workspace = false;
    },

    _closeWaitingValueWindow() {
      this.show_waiting = false;
    },

    _changeOrderType: function(value){
      if(value == "open"){
        this.select_order_type = true;
        this.order_class = false;
      }else{
        this.select_order_type = false;
        this.order_class = true;
      }
    },

    _orderedStock: function(field, type, name, header){
      if(field == 'anual_var'){
        field = 'tae';
      }
      if(this.stocks) {
        for(let i = 0; i < this.stocks.length; i++){
          this.ordered_stocks[i] = this.stocks[i];
        }
        if(type == "asc"){
          this.ordered_stocks.sort((a,b) => {
            if (this.isNumeric(a[field]) && this.isNumeric(b[field])) {
              return parseFloat(a[field]) > parseFloat(b[field]) ? 1 : -1;
            } else {
              return a[field] > b[field] ? 1 : -1;
            }
          })
          this.not_ordered = false;
          this.order_type = name
          let setOrder = [type, header]
          this.setOrderBy(setOrder)
        }else if(type == "desc"){
          this.ordered_stocks.sort((a,b) => {
            if (this.isNumeric(a[field]) && this.isNumeric(b[field])) {
              return parseFloat(a[field]) < parseFloat(b[field]) ? 1 : -1;
            } else {
              return a[field] < b[field] ? 1 : -1;
            }
          })
          this.not_ordered = false;
          this.order_type = name
          let setOrder = [type, header]
          this.setOrderBy(setOrder)
        }
        this._changeOrderType("close");
      }
    },

    isNumeric : function(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },

    _getOrderParameters(){
      
      let order_preferences = {
        field: "",
        header: "",
        name: "",
        type: ""
      };

      for(let i = 0; i < this.order_by_preferences.length; i++){
        if(this.order_by_preferences[i].header == this._g_order_by[1]){
          order_preferences.field = this.order_by_preferences[i].field;
          order_preferences.header = this.order_by_preferences[i].header;
          if(this._g_order_by[0] == 'asc'){
            order_preferences.type = 'asc';
            order_preferences.name = this.order_by_preferences[i].name[0]
          } else {
            order_preferences.type = 'desc';
            order_preferences.name = this.order_by_preferences[i].name[1];
          }
        }
      }

      return order_preferences;
    },

    _checkPreferencesToOrderBy: function(){
      let temp = [];
      for(let i = 0; i < this.order_by_preferences.length; i++){
        temp.push(this.order_by_preferences[i].id)
      }
      this.preferences.forEach(element =>{
        if(temp.indexOf(element.name) != -1){
          let index = temp.indexOf(element.name);
          this.preferences_selected_to_order.push(this.order_by_preferences[index])
        }
      })
      this._checkWhatPreferenceIsOrdered()
    },

    _getPortfolioPreferences: function(){
      let success = response =>{
        if(response.data.indexOf("open_fee") != -1){
          this.preferences.push({field: "open_fee", help_text: "", name:"Comisión de entrada", sorter:"Comisión ent.", subfix: "", type: ""})
        }
        if(response.data.indexOf("benefit") != -1){
          this.preferences.push({field: "benefit", help_text: "", name:"Beneficio", sorter:"Beneficio", subfix: "", type: ""})
        }
        if(response.data.indexOf("insured_benefit") != -1){
          this.preferences.push({field: "insured_benefit", help_text: "", name:"Beneficio asegurado", sorter:"Benef. Aseg.", subfix: "", type: ""})
        }
        if(response.data.indexOf("in_date") != -1){
          this.preferences.push({field: "in_date", help_text: "", name:"Fecha de entrada", sorter:"F. entrada", subfix: "", type: ""})
        }
        this._checkPreferencesToOrderBy();
      }

      let url = "/api/v1/portfolio-preferences"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('086', 'Error cargando preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _checkWhatPreferenceIsOrdered: function(){
      let temp = []
      for(let i = 0; i < this.preferences_selected_to_order.length; i++){
        temp.push(this.preferences_selected_to_order[i].header)
      }
      let index = temp.indexOf(this._g_order_by[1])
      let element = this.preferences_selected_to_order[index]
      let name = ""
      if(element) {
        if(this._g_order_by[0] == "asc"){
          name = element.name[0]
        }else{
          name = element.name[1]
        }
        this._orderedStock(element.field, this._g_order_by[0], name, element.header)
      }
    }
  },
}
</script>


<style lang="scss" scoped>
@use "@material/list";

@include list.core-styles;
@include list.item-selected-text-color(black);

.mdc-list-item {
  @include list.item-primary-text-ink-color(#444);
  @include list.list-item-height(36px);
  border-bottom: 1px solid #e9e9e9;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple[data-v-1d7efbc2]::before {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, red));
}

li.mdc-list-item {display:flex;}
li span.mdc-list-item__meta.material-icons {margin:unset; margin-left: 4px}
li span.mdc-list-item__meta.material-icons.push {margin-left: auto;}

.mdc-list-item.mdc-list-item--selected::before {
  display:none;
}

.mdc-list-item--selected .typo-body-2{
  font-weight:bold;
}

li.mdc-list-item--selected .material-icons {
  &.alarm:hover {color:green}
  &.notifications:hover {color:orange;}
  &.favorite:hover {color:#00add8;}
  &.folder:hover{color: #89430C;}
}

li.mdc-list-item .material-icons {
  &.alarm.active {color:green}
  &.notifications.active {color:orange;}
  &.favorite.active {color:#00add8;}
  &.folder.active{color: #89430C;}
}

.favorite, .alarm {
  font-size: 22px;
}

/* Add style for multi-favorite white stars */
.stock-icon-on-bg {
  color: white !important;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
}

.ekers-event-click {
  cursor:pointer;
}
.stocks-container{
  margin-left: 12px;
  background-color: var(--color-background-background);
  border-right: 1px solid var(--color-custom-only-dark-theme-border);
}

.stocks-order-query{
  height: 30px;
  border: 1px solid rgba(15, 15, 26, 0.2);
}

.stocks-order-query:hover{
  cursor: pointer;

}

.order-text{
  margin: 10px 0 10px 7px;
}
.div-text-order:hover{
  background-color: #e5e5e5;
}
.div-text-order{
  padding: 10px 0 10px 7px;
}

.order-menu{
  position: absolute; 
  background: #FBFBFB;
  border: 1px solid #E0E1E6;
  width: 95.7%; 
  opacity: 1; 
  z-index:1; 
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.4px;

  color: var(--color-primary-900);
  height: 600px;
  overflow-y: scroll;
}

.blured{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}

.mdc-list-item__text{
  color: var(--color-primary-secondary);
}

.stocks-info-query{
  background-color: var(--color-primary-900);
}

.stocks-query-not-found{
  padding: 20px;
  text-align: center;
  color: var(--color-text-secondary);
}

.star-margin{
  margin-left: auto !important;
}

.not-allowed-stocks{
  color: #00add9;
}

.pre-eurekers-stocks-info{
  background-color: #000010FF;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
}

.pre-eurekers-number{
  color: #00add9 !important;
  font-size: 18px !important;
  font-weight: bold;
}
</style>