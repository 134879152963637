<template>
    <div class="filter-section">
        <div class="second-filters-container filter-group" v-if="local_array_stocks_filters.length != 0">
            <!-- Montante Group -->
            <div class="filter-group montante-group" v-if="crypto_enabled==false">
                <h4 class="filter-group-title">
                    Montante:
                    <!-- Removed Firefox specific update logic for simplicity -->
                    <!-- <span class="update-montante" v-if="is_firefox && is_focused" @click="_updateMontante">Actualizar montante</span> -->
                </h4>
                <div class="pair-container" v-if="_g_UserFeatures.has_active_role">
                    <div class="input-wrapper">
                         <label for="filters-second-montante-inf" class="input-label">Límite inferior</label>
                         <input 
                            type="number"
                            :value="local_array_stocks_filters[0].value" 
                            @input="saveMontanteInf($event.target.value)"
                            placeholder="0"
                            min="0"
                            id="filters-second-montante-inf"
                            class="vanilla-input number-input"
                         />
                    </div>
                   <div class="input-wrapper">
                        <label for="filters-second-montante-sup" class="input-label">Límite superior</label>
                        <input 
                            type="number"
                            :value="local_array_stocks_filters[1].value"
                            @input="saveMontanteSup($event.target.value)"
                            placeholder="∞"
                            min="0"
                            id="filters-second-montante-sup"
                            class="vanilla-input number-input"
                         />
                   </div>
                </div>
                <div v-else style="margin-bottom: 10px;">
                    <FilterNoPermission :link="buy_link" />
                </div>
            </div>

            <!-- Distancia Entrada Group -->
            <div class="filter-group distancia-group">
                <h4 class="filter-group-title">
                    Distancia de la entrada:
                </h4>
                <div class="pair-container" v-if="_g_UserFeatures.has_active_role">
                    <!-- Loop through Selects for Distancia -->
                     <div class="select-wrapper" v-for="(iSelect, selectIndex) in second_config[1].componentsConfig" :key="iSelect.component_class">
                         <label :for="iSelect.component_class" class="input-label">{{ iSelect.label_name }}</label>
                         <select 
                            :id="iSelect.component_class"
                            class="vanilla-select"
                            :value="local_array_stocks_filters[selectIndex + 2].value" 
                            @change="_handleSelectChange($event, iSelect.component_class)"
                         >
                             <option 
                                v-for="option in iSelect.options" 
                                :key="option.value"
                                :value="option.value"
                             >
                                {{ option.name }}
                             </option>
                         </select>
                         <!-- Optional Helper Text -->
                         <!-- <span v-if="iSelect.helper_select_permission" class="helper-text">{{ iSelect.helper_text_select }}</span> -->
                     </div>
                </div>
                <div v-else>
                    <FilterNoPermission :link="buy_link" />
                </div>
            </div>

             <!-- Edad Group -->
            <div class="filter-group edad-group">
                <h4 class="filter-group-title">
                    Edad:
                </h4>
                 <div class="pair-container" v-if="_g_UserFeatures.has_active_role">
                    <!-- Loop through Selects for Edad -->
                     <div class="select-wrapper" v-for="(iSelect, selectIndex) in second_config[2].componentsConfig" :key="iSelect.component_class">
                         <label :for="iSelect.component_class" class="input-label">{{ iSelect.label_name }}</label>
                         <select 
                            :id="iSelect.component_class"
                            class="vanilla-select"
                            :value="local_array_stocks_filters[selectIndex + 4].value" 
                            @change="_handleSelectChange($event, iSelect.component_class)"
                         >
                            <option 
                                v-for="option in iSelect.options" 
                                :key="option.value"
                                :value="option.value"
                            >
                                {{ option.name }}
                             </option>
                         </select>
                         <!-- Optional Helper Text -->
                         <!-- <span v-if="iSelect.helper_select_permission" class="helper-text">{{ iSelect.helper_text_select }}</span> -->
                    </div>
                </div>
                <div v-else>
                    <FilterNoPermission :link="buy_link" />
                </div>
            </div>

            <!-- Dividendos Group -->
            <div class="filter-group dividendos-group" v-if="crypto_enabled==false">
                <h4 class="filter-group-title">
                    Dividendos:
                </h4>
                <div class="checkbox-grid" v-if="_g_UserFeatures.has_active_role">
                    <!-- Loop through checkboxes for Dividendos -->
                    <div class="checkbox-item" v-for="(iCheckbox, checkboxIndex) in second_config[3].componentsConfig" :key="iCheckbox.checkbox_id">
                         <input 
                            type="checkbox" 
                            class="vanilla-checkbox" 
                            :id="iCheckbox.checkbox_id" 
                            :checked="local_array_stocks_filters[checkboxIndex + 6].value" 
                            :disabled="false" 
                            @change="_handleCheckboxChange($event, iCheckbox.checkbox_key)" 
                        />
                        <label :for="iCheckbox.checkbox_id" class="checkbox-label">{{ iCheckbox.label_name }}</label>
                    </div>
                </div>
                <div v-else>
                    <FilterNoPermission :link="buy_link" />
                </div>
            </div>

        </div>
         <div v-else class="loading-placeholder">
            <!-- Cargando filtros... -->
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
// Remove unused component imports
// import CheckBox from "@/components/helpers/CheckBox";
import SecondConfig from "@/components/stockchart/components/Finder/configs/Second.json";
// import SecondConfigCripto from "@/components/stockchart/components/Finder/configs/Second_cripto.json"; // Keep if needed
// import SelectOutline from '@/components/helpers/newMaterial/SelectInputFilters.vue';
// import DigitOutlineInputFilters from '@/components/helpers/newMaterial/DigitOutlineInputFilters.vue';
import FilterNoPermission from "@/components/stockchart/components/Finder/FilterNoPermission";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    name: 'SecondFilterGroup', // Renamed for clarity
    components: {
        // Removed CheckBox, SelectOutline, DigitOutlineInputFilters
        FilterNoPermission,
    },
    props: {
        filters_user_json:{type: Object, default: () => ({}) },
    },
    data: () => ({
        second_config: SecondConfig,
        // second_config_cripto: SecondConfigCripto,
        local_array_stocks_filters: [], // Holds state for inputs/selects/checkboxes
        finder: null, // Holds the local copy of the finder object
        buy_link: "",
        // Removed Firefox specific state
        // is_firefox: false,
        // is_focused: false,
    }),
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
        // Removed Firefox detection
        // var userAgent = navigator.userAgent;
        // this.is_firefox = userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Safari") != -1;
    },
    created(){
        // Watcher pattern replaces interval logic
        // this.finder = this._g_FinderFilters(); 
        // var created_interval = setInterval(() => {
        //     if (this.filters_user_json != "") { // filters_user_json might not be needed if _g_UserFeatures handles permissions
        //         this._getFinderParameters(this._g_FinderFilters);
        //         clearInterval(created_interval);
        //     }
        // }, 200);
    },
    watch: {
         // Watch for changes in the global finder filters from the parent
        _g_FinderFilters: {
            handler(newFinder) {
                if (newFinder && Object.keys(newFinder).length > 0) {
                    // console.log("Second.vue: _g_FinderFilters changed, updating local state.");
                    this._getFinderParameters(newFinder); 
                } else {
                    // Handle case where filters are reset or unavailable
                    this.local_array_stocks_filters = [];
                    this.finder = null;
                }
            },
            immediate: true, // Run on component mount
            deep: true // Watch for nested changes
        }
    },
    computed: {
        ...mapGetters(['_g_crypto_enable', '_g_UserFeatures', '_g_FinderFilters']),
        // Use _g_crypto_enable directly in the template
         crypto_enabled() {
           return this._g_crypto_enable;
         }
    },
    methods: {
        ...mapMutations(['setFinderFilters']), 
        // Removed getter from methods
        // ...mapGetters(['_g_FinderFilters']),

        // Copied from First.vue - reuse for consistency
        _handleCheckboxChange: function(event, key) {
            const newValue = event.target.checked;
            this._getValueFromCheckbox([newValue, key]);
        },
        
        _getValueFromCheckbox: function(value){
             if (!this.finder) return;
             const newValue = value[0];
             const key = value[1];
             this.finder.dividends = this.finder.dividends || []; // Ensure array exists
             let changed = true;

             switch(key){
                case "key_0":         this.finder.dividends[0] = newValue; break;
                case "key_1":         this.finder.dividends[1] = newValue; break;
                case "key_2":         this.finder.dividends[2] = newValue; break;
                case "key_3omas":     this.finder.dividends[3] = newValue; break;
                default:
                    console.warn(`Unknown checkbox key: ${key}`);
                    changed = false;
                    break;
             }
             if (changed) {
                this._dataToNewFormat(); // Update local array for UI consistency
                this._updateLastSearch2();
             }
        },

        // New handler for vanilla select change
         _handleSelectChange: function(event, key) {
            const newValue = event.target.value;
             this._getValueFromSelect([newValue, key]);
        },

         _getValueFromSelect: function(value){
            if (!this.finder) return;
            const newValue = value[0];
            const key = value[1]; // Corresponds to the id/component_class
            let changed = true;

            // Parse value based on key
            switch(key){
                case "entradaInferior": this.finder.min_entry_distance = parseInt(newValue.replace("%", "")); break;
                case "entradaSuperior": this.finder.max_entry_distance = parseInt(newValue.replace("% o más", "").replace("%", "")); break;
                case "edadInferior":    this.finder.min_edad = newValue === '< 1' ? '' : parseInt(newValue); break;
                case "edadSuperior":    this.finder.max_edad = newValue === '> 25' ? '' : parseInt(newValue); break;
                default:
                    console.warn(`Unknown select key: ${key}`);
                    changed = false;
                    break;
            }

            if (changed) {
                this._dataToNewFormat(); // Update local array for UI consistency
                this._updateLastSearch2();
            }
        },

        // Removed old _getValueFromInput - combined into saveMontante handlers
        // _getValueFromInput: function(value){ ... }
        
        // Simplified Montante handlers - directly update finder and call update
        saveMontanteInf: function(value) {
             if (!this.finder) return;
             // Ensure the object exists
             this.finder.min_amount = parseInt(value) || 0; // Use 0 if empty or invalid
             this._dataToNewFormat(); // Update local array representation
             this._updateLastSearch2(); 
        },

        saveMontanteSup: function(value) {
            if (!this.finder) return;
             // Use a large number representation for infinity, or null/undefined if preferred by backend
             this.finder.max_amount = value === '' ? 99999999999999 : (parseInt(value) || 99999999999999);
             this._dataToNewFormat(); // Update local array representation
             this._updateLastSearch2();
        },

        // Removed Firefox specific update method
        // _updateMontante: function(){ ... },

        // Adjusted to accept finder data as argument
        _getFinderParameters: function(finderData){
            if (!finderData) return;
            this.finder = JSON.parse(JSON.stringify(finderData)); // Deep copy
            this._dataToNewFormat();
        },

        // Updated to handle potentially missing finder or nested props
        _dataToNewFormat: function(){    
            if (!this.finder) {
                this.local_array_stocks_filters = [];
                return;
            }
        
            let finder = this.finder;
            let max_entry_value = "";
            let max_edad = "";
            let min_edad = "";

            // Handle max_entry_distance conversion
            if (finder.max_entry_distance === undefined || finder.max_entry_distance === null) {
                 max_entry_value = '0%'; // Default or placeholder
            } else if (finder.max_entry_distance == 25) {
                max_entry_value = "25% o más";
            } else {
                max_entry_value = finder.max_entry_distance + "%";
            }
            const min_entry_value = finder.min_entry_distance !== undefined && finder.min_entry_distance !== null ? finder.min_entry_distance + "%" : '0%';

            // Handle edad conversion
            if (finder.min_edad === "" || finder.min_edad === undefined || finder.min_edad === null) {
                min_edad = "< 1";
            } else {
                min_edad = finder.min_edad.toString();
            }
            if (finder.max_edad === "" || finder.max_edad === undefined || finder.max_edad === null) {
                max_edad = "> 25";
            } else {
                max_edad = finder.max_edad.toString();
            }

            // Handle max_amount parsing for display
            let max_amount_parse = "";
            if (finder.max_amount === 99999999999999 || finder.max_amount === undefined || finder.max_amount === null) {
                max_amount_parse = ""; // Display as empty for infinity placeholder
            } else {
                max_amount_parse = finder.max_amount.toString();
            }
            const min_amount_parse = finder.min_amount !== undefined && finder.min_amount !== null ? finder.min_amount.toString() : '0';
            
            // Ensure dividends array exists and has values
            const dividends = finder.dividends || [false, false, false, false];

            // Create the local array used by the template bindings
            this.local_array_stocks_filters = [
                {
                    name : "min_amount",
                    value: min_amount_parse,                
                },
                {
                    name : "max_amount",
                    value: max_amount_parse,                    
                },
                {
                    name : "min_entry_distance",
                    value: min_entry_value,                    
                },
                {
                    name : "max_entry_distance",
                    value: max_entry_value,                    
                },
                {
                    name : "min_edad",
                    value: min_edad,                    
                },
                {
                    name : "max_edad",
                    value: max_edad, 
                },{
                    name : "dividend_0",
                    value: dividends[0] || false,
                },{
                    name : "dividend_1",
                    value: dividends[1] || false, 
                },{
                    name : "dividend_2",
                    value: dividends[2] || false, 
                },{
                    name : "dividend_3ormore",
                    value: dividends[3] || false,
                }
            ];
        },
        
        // Reusable method to update global state and emit event
        _updateLastSearch2: function(){
            if (!this.finder) return;
            this.setFinderFilters(JSON.parse(JSON.stringify(this.finder))); // Update Vuex state
            this.$emit("updateLastSearch"); // Notify parent
        }, 
    },
}
</script>

<style lang="scss" scoped>
/* Import shared variables/styles if needed */
/* @import '@/styles/_variables.scss'; */

/* Commented out @import - styles are now inherited from FinderBase */
/* @import '@/components/stockchart/components/Finder/First.vue.scss'; */

/* All common styles (.filter-section, .filter-group, .filter-group-title, */
/* .pair-container, .input-wrapper, .select-wrapper, .input-label, */
/* .vanilla-input, .vanilla-select, .number-input, .checkbox-grid, */
/* .checkbox-item, .vanilla-checkbox, .checkbox-label, .loading-placeholder) */
/* are now defined in the UNSCOPED <style> block in Finder-Base.vue */

/* Add any styles SPECIFIC to Second.vue here, if necessary. */

</style>