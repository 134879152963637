<template>
    <label :id="id + '-component'" class="mdc-text-field mdc-text-field--outlined digit-outline-filters">
        <span class="mdc-notched-outline mdc-input-decorations">
            <span class="mdc-notched-outline__leading"></span>
            <span class="mdc-notched-outline__notch notch">
                <span class="mdc-floating-label" :id="id"> {{label}} </span>
            </span>
            <span class="mdc-notched-outline__trailing"></span>
        </span>
        <input
        v-model="input_value"
        type="text"
        class="mdc-text-field__input"
        :aria-labelledby="id"
        :placeholder="placeholder">
    </label>
</template>
<script>
    import {MDCTextField} from '@material/textfield';
    export default {
        name: 'DigitOutlineInputFilters',
        data() {
            return {
                input_value: '',
                text_field: null,
            };
        },
        props: {
            id: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            placeholder: {
                type: Number,
                required: true
            }
        },
        watch: {
            input_value(new_val, old_val) {
                const max_size = 15;
                const new_val_is_valid_digit = /^\d*[.,]?\d*$/.test(new_val) && new_val.length <= max_size;
                if(new_val_is_valid_digit) {
                    const formatted_new_val = new_val.replace(',', '.');
                    this.input_value = formatted_new_val;
                    this.$emit('inputChanged', formatted_new_val);
                }
                else {
                    this.input_value = old_val;
                }
            }
        },
        mounted() {
            this.text_field = new MDCTextField(document.querySelector('#' + this.id + '-component'));
        },
        methods: {
            handleKeyPress: function(event) {
                const maxSize = 15;
                const is_max_size_input_string = this.input_value.length >= maxSize;
                const is_already_float_current_string = this.input_value.indexOf('.') != -1;
                const is_dottable_char_inputted = event.key == ',' || event.key == '\'';
                const is_dot_inputted = event.key == '.';
                const is_digit_inputted = /\d/.test(event.key);

                if(is_max_size_input_string || (!is_digit_inputted && !is_dot_inputted && !is_dottable_char_inputted) || (is_already_float_current_string && (is_dot_inputted || is_dottable_char_inputted))) {
                    event.preventDefault();
                }
                else if(!is_digit_inputted && is_dottable_char_inputted) {
                    event.preventDefault();
                    this.input_value += '.';
                }
            }
        }
  }
</script>
<style lang="scss" scoped>
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
@include textfield.core-styles;

.digit-outline-filters {
    --digitinput-border-width: 1px;
    --digitinput-border-color: rgba(0, 0, 0, 0.1);
}

.digit-outline-filters.mdc-text-field {
    @include textfield.disabled-fill-color(#F3F4F7);
    @include textfield.ink-color(var(--color-custom-filter-inputs-font-color));
    @include textfield.label-color(var(--color-custom-filter-inputs-font-color)); //color del label que esta en el input y que luego se pone arriba
    @include textfield.placeholder-color(rgba(0, 0, 0, 0.6));
    @include textfield.disabled-ink-color(rgba(0, 0, 0, 0.6));
    @include textfield.outline-color(#c7c8cc);//color del borde cuando no esta seleccionado
    @include textfield.outlined-height(50px, 50px);
    //@include textfield.fill-color(var(--color-background-background-casillas));
    --mdc-theme-primary: rgba(0, 0, 0, 0.6); //con esta linea cambiamos el borde del input cuando esta seleccionado.
    @include textfield.hover-outline-color(#343948);
    @include textfield.focused-outline-color(#343948);
}

.digit-outline-filters .mdc-input-decorations > span {
    border-width: var(--digitinput-border-width) !important;
    //background-color: var() !important;
}

.digit-outline-filters .mdc-input-decorations .notch {
    border-left: none !important;
    border-right: none !important;
}

.digit-outline-filters input {
    z-index: 5;
}

</style>