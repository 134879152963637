<template>
    <div v-if="local_array_stocks_filters.length != 0" class="first filter-section" :key="force_re_render">
        <!-- Rentabilidad Group -->
        <div class="filter-group rentabilidad-group" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
            <h4 class="filter-group-title">
                        Rentabilidad
                    </h4>
            <div class="checkbox-grid rentabilidad-grid">
                <!-- Loop through checkboxes for Rentabilidad -->
                <div class="checkbox-item" v-for="(cbox, cboxIndex) in first_config[0].componentsConfig" :key="cbox.checkbox_id">
                    <input 
                        type="checkbox" 
                        class="vanilla-checkbox" 
                        :id="cbox.checkbox_id" 
                        :checked="local_array_stocks_filters[cboxIndex].value" 
                        :disabled="false"
                        @change="_handleCheckboxChange($event, cbox.checkbox_key)" 
                    />
                    <label :for="cbox.checkbox_id" class="checkbox-label">{{ cbox.label_name }}</label>
                </div>
                </div>
            </div>

        <!-- Timing Group -->
        <div class="filter-group timing-group" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
            <h4 class="filter-group-title">
                        Timing
                    </h4>
            <div class="checkbox-grid">
                <!-- Loop through checkboxes for Timing -->
                <div class="checkbox-item" v-for="(cbox, cboxIndex) in first_config[1].componentsConfig" :key="cbox.checkbox_id">
                    <input 
                        type="checkbox" 
                        class="vanilla-checkbox" 
                        :id="cbox.checkbox_id" 
                        :checked="local_array_stocks_filters[cboxIndex + 5].value" 
                        :disabled="false" 
                        @change="_handleCheckboxChange($event, cbox.checkbox_key)" 
                    />
                    <label :for="cbox.checkbox_id" class="checkbox-label">{{ cbox.label_name }}</label>
                </div>
            </div>
            </div>

        <!-- Dirección Group -->
        <div class="filter-group direccion-group" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
            <h4 class="filter-group-title">
                        Dirección
                    </h4>
             <div class="checkbox-grid">
                <!-- Loop through checkboxes for Dirección -->
                <div class="checkbox-item" v-for="(cbox, cboxIndex) in first_config[2].componentsConfig" :key="cbox.checkbox_id">
                    <input 
                        type="checkbox" 
                        class="vanilla-checkbox" 
                        :id="cbox.checkbox_id" 
                        :checked="local_array_stocks_filters[cboxIndex + 7].value" 
                        :disabled="false" 
                        @change="_handleCheckboxChange($event, cbox.checkbox_key)" 
                    />
                    <label :for="cbox.checkbox_id" class="checkbox-label">{{ cbox.label_name }}</label>
                </div>
                </div>
            </div>

        <!-- Tipo Group (Conditional based on user role) -->
        <div class="filter-group tipo-group">
            <h4 class="filter-group-title">
                        Tipo
                    </h4>
            <div class="checkbox-grid">
                 <!-- Admin/Advanced Role View -->
                <template v-if="_g_User.roles[21] || _g_User.roles[20]">
                     <div class="checkbox-item" v-for="(cbox, cboxIndex) in first_config[5].componentsConfig" :key="cbox.checkbox_id">
                        <input 
                            type="checkbox" 
                            class="vanilla-checkbox" 
                            :id="cbox.checkbox_id" 
                            :checked="cbox.label_name === 'MKT' ? local_array_stocks_filters[cboxIndex + 14].value : local_array_stocks_filters[cboxIndex + 9].value" 
                            :disabled="false" 
                            @change="_handleCheckboxChange($event, cbox.checkbox_key)" 
                        />
                        <label :for="cbox.checkbox_id" class="checkbox-label">{{ cbox.label_name }}</label>
                </div>
                </template>
                 <!-- Standard Role View -->
                <template v-else>
                    <div class="checkbox-item" v-for="(cbox, cboxIndex) in first_config[4].componentsConfig" :key="cbox.checkbox_id">
                        <input 
                            type="checkbox" 
                            class="vanilla-checkbox" 
                            :id="cbox.checkbox_id" 
                            :checked="local_array_stocks_filters[cboxIndex + 9].value" 
                            :disabled="false" 
                            @change="_handleCheckboxChange($event, cbox.checkbox_key)" 
                        />
                        <label :for="cbox.checkbox_id" class="checkbox-label">{{ cbox.label_name }}</label>
                    </div>
                </template>
                </div>
            </div>

        <!-- Tipo de Valor Group -->
        <div class="filter-group tipo-valor-group" v-if="!(_g_User.roles[21] || _g_User.roles[20])">
            <h4 class="filter-group-title">
                        Tipo de valor
                    </h4>
            <div class="checkbox-grid">
                <!-- Loop through checkboxes for Tipo de Valor -->
                <div class="checkbox-item" v-for="(cbox, cboxIndex) in first_config[3].componentsConfig" :key="cbox.checkbox_id">
                    <input 
                        type="checkbox" 
                        class="vanilla-checkbox" 
                        :id="cbox.checkbox_id" 
                        :checked="local_array_stocks_filters[cboxIndex + 16].value" 
                        :disabled="false" 
                        @change="_handleCheckboxChange($event, cbox.checkbox_key)" 
                    />
                    <label :for="cbox.checkbox_id" class="checkbox-label">{{ cbox.label_name }}</label>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="loading-placeholder">
      <!-- Optional: Add a loading indicator if needed -->
      <!-- Cargando filtros... -->
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
// Remove CheckBox import
// import CheckBox from "@/components/helpers/CheckBox"; 
import FirstConfig from "@/components/stockchart/components/Finder/configs/First.json";
import FirstConfigCripto from "@/components/stockchart/components/Finder/configs/First_cripto.json";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
    mixins: [APICaller],
    name: 'FirstFilterGroup', // Renamed for clarity
    components: {
        // CheckBox removed
    },
    props: {
        filters_user_json:{type: Object, default: () => ({}) }, // Added default        
    },
    data: () => ({
        first_config: FirstConfig,
        // first_config_cripto: FirstConfigCripto, // Keep if needed for crypto mode
        force_re_render: 0, // Keep if useful for reactivity
        // Removed permissions flags if handled by roles directly
        // rentabilidad_permission:"", 
        // ...
        // Removed changes flag if not used
        // changes: true, 
        local_array_stocks_filters: [], // Holds the state for checkboxes
        finder: null, // Initialize finder to null or empty object
        buy_link: ""
    }),
    mounted() {
        this.buy_link = this.$detectEnvironment() + '/app/store';
        // Initial fetch of finder parameters
        // this._getFinderParameters(); // Maybe call this when _g_FinderFilters becomes available (see watcher)
    },
    created: function() {     
        // this.finder = this._g_FinderFilters(); // Get initial finder state if available
        // The interval logic might be replaceable with a watcher
    },
    computed: {
        ...mapGetters(['_g_crypto_enable', '_g_User', '_g_FinderFilters']), // Added _g_FinderFilters
        // Removed crypto_enabled computed if _g_crypto_enable is used directly or not needed here
        // ...mapGetters({crypto_enabled:'_g_crypto_enable'}),
    },
     watch: {
        // Watch for changes in the global finder filters from the parent
        _g_FinderFilters: {
            handler(newFinder) {
                if (newFinder && Object.keys(newFinder).length > 0) {
                    // console.log("First.vue: _g_FinderFilters changed, updating local state.");
                    this._getFinderParameters(newFinder); 
                } else {
                    // Handle case where filters are reset or unavailable
                    this.local_array_stocks_filters = [];
                    this.finder = null;
                }
            },
            immediate: true, // Run on component mount
            deep: true // Watch for nested changes
        }
    },
    methods: {
        ...mapMutations(['setFinderFilters']), // Removed setRechargeState if not used
        // Removed redundant _g_FinderFilters getter from methods
        // ...mapGetters(['_g_FinderFilters']), 
        
        // Renamed _getFinderParameters to accept finder data as argument
        _getFinderParameters: function(finderData) {
            if (!finderData) {
                // console.warn("First.vue: Attempted to get parameters with no finder data.");
                return;
            }
            this.finder = JSON.parse(JSON.stringify(finderData)); // Deep copy to avoid mutation issues
            this._dataToNewFormat();
            this.force_re_render++; // Force re-render if needed after data update
        },
        
        _dataToNewFormat: function(){
            if (!this.finder) return; // Guard clause

            var finder = this.finder; // Use the local deep copy
            // Ensure nested objects exist before accessing
            const taeCheckboxes = finder.tae_checkboxes || {};
            const esIpo = finder.es_ipo || [];
            const direccio = finder.direccio || [];
            const entryType = finder.entry_type || {};
            const valueType = finder.value_type || {};

            var checkbox = [
                {
                    name : "<35",
                    value: taeCheckboxes["M"] || false,                
                },
                {
                    name : "35-75",
                    value: taeCheckboxes["A"] || false,                    
                },
                {
                    name : "75-100",
                    value: taeCheckboxes["B"] || false,                    
                },
                {
                    name : "150-400",
                    value: taeCheckboxes["C"] || false,                    
                },
                {
                    name : ">400",
                    value: taeCheckboxes["D"] || false,                    
                },
                {
                    name : "mensual",
                    value: esIpo[0] || false, 
                },{
                    name : "IPO",
                    value: esIpo[1] || false,
                },{
                    name : "bajista",
                    value: direccio[0] || false, 
                },{
                    name : "alcista",
                    value: direccio[1] || false, 
                },{
                    name : "PER",
                    value: entryType["per"] || false,
                },{
                    name : "RR",
                    value: entryType["0"] || false,
                },{
                    name : "RRA",
                    value: entryType["1"] || false,
                },{
                    name : "LT",
                    value: entryType["2"] || false,
                },{
                    name : "LTA",
                    value: entryType["3"] || false,
                },{
                    name  : "SE",
                    value: entryType["6"] || false,
                },{
                    name  : "MKT",
                    value: entryType["7"] || false,
                },{
                    name : "stock",
                    value: valueType["Stock"] || false,
                },{
                    name : "ETF",
                    value: valueType["ETF"] || false,
                },
            ];
            this.local_array_stocks_filters = checkbox;
        },

        // New handler for vanilla checkbox change event
        _handleCheckboxChange: function(event, key) {
            const newValue = event.target.checked;
            // console.log(`Checkbox changed: Key=${key}, NewValue=${newValue}`);
            this._getValueFromCheckbox([newValue, key]);
        },

        _getValueFromCheckbox: function(value){
            // This method now updates the local `this.finder` object
            // based on the key and new value, then calls `_updateLastSearch2`.
            if (!this.finder) {
                 console.error("Finder object not initialized in _getValueFromCheckbox");
                 return;
            }
            
            const newValue = value[0];
            const key = value[1];
            
            // Initialize nested objects if they don't exist
            this.finder.tae_checkboxes = this.finder.tae_checkboxes || {};
            this.finder.es_ipo = this.finder.es_ipo || [];
            this.finder.direccio = this.finder.direccio || [];
            this.finder.entry_type = this.finder.entry_type || {};
            this.finder.value_type = this.finder.value_type || {};
            
            let changed = true; // Flag to check if a change was actually made

            // Update the corresponding property in the local finder copy
            switch (key) {
                case "key_less35":    this.finder.tae_checkboxes["M"] = newValue; break;
                case "key_35-75":     this.finder.tae_checkboxes["A"] = newValue; break;
                case "key_75-150":    this.finder.tae_checkboxes["B"] = newValue; break;
                case "key_150-400":   this.finder.tae_checkboxes["C"] = newValue; break;
                case "key_more400":   this.finder.tae_checkboxes["D"] = newValue; break;
                case "key_mensual":   this.finder.es_ipo[0] = newValue; break;
                case "key_IPO":       this.finder.es_ipo[1] = newValue; break;
                case "key_bajista":   this.finder.direccio[0] = newValue; break;
                case "key_alcista":   this.finder.direccio[1] = newValue; break;
                case "key_stock":     this.finder.value_type["Stock"] = newValue; break;
                case "key_ETF":       this.finder.value_type["ETF"] = newValue; break;
                case "key_PER":       this.finder.entry_type["per"] = newValue; break;
                case "key_RR":        this.finder.entry_type["0"] = newValue; break;
                case "key_RRA":       this.finder.entry_type["1"] = newValue; break;
                case "key_LT":        this.finder.entry_type["2"] = newValue; break;
                case "key_LTA":       this.finder.entry_type["3"] = newValue; break;
                case "key_SE":        this.finder.entry_type["6"] = newValue; break;
                case "key_MKT":       this.finder.entry_type["7"] = newValue; break;
                default:
                    console.warn(`Unknown checkbox key: ${key}`);
                    changed = false;
                    break;
            }
            
            if (changed) {
                // Update the local representation for immediate UI feedback
                this._dataToNewFormat(); 
                 // Call the method to update the global state and notify the parent
                this._updateLastSearch2();
            }
        },
       
        _updateLastSearch2: function(){
            if (!this.finder) return;
            // Set the global finder filters in Vuex
            this.setFinderFilters(JSON.parse(JSON.stringify(this.finder))); // Pass deep copy
            // Emit event to parent (Finder-Base.vue) to trigger API call if needed
            this.$emit("updateLastSearch"); // No need to pass data, parent reads from Vuex
        },       
    },
}
</script>

<style lang="scss" scoped>
/* Import shared variables if available */
/* @import '@/styles/_variables.scss'; */

/* All common styles (.filter-section, .filter-group, .filter-group-title, */
/* .checkbox-grid, .checkbox-item, .vanilla-checkbox, .checkbox-label, */
/* .loading-placeholder) are now defined in the UNSCOPED <style> block */
/* in Finder-Base.vue */

/* Add any styles SPECIFIC to First.vue here */

.premium-indicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    color: #FFC107; /* Gold color */
    background-color: rgba(255, 193, 7, 0.15);
    border: 1px solid rgba(255, 193, 7, 0.5);
    border-radius: 4px;
    padding: 1px 4px;
    line-height: 1;
    margin-left: 4px;
}

/* Keep specific grid adjustments if they were needed */
.rentabilidad-grid {
     /* grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); */ /* Example if needed */
}

/* Keep premium link styling if the link might reappear based on logic */
/*
.premium-link {
    font-size: 11px;
    font-weight: 600;
    color: var(--primary, #00aedd);
    text-decoration: none;
    white-space: nowrap;
    margin-left: 10px; 
    transition: color 0.2s ease;
    place-self: center; 
}
.premium-link:hover {
    color: var(--primary-dark, #007A9E);
    text-decoration: underline;
}
*/

</style>