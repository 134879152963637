<template>
  <div id="stock-table-custom" class="mdc-data-table table-custom">
    <div>
      <table class="mdc-data-table__table full-table" aria-label="Sector stocks">
        <thead class="top-table">
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort" role="columnheader" scope="col" aria-sort="none" data-column-id="m1">
            <div class="mdc-data-table__header-cell-wrapper">
                <button v-if="!is_sorted" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 mounth" aria-describedby="protein-status-label" @click="_shortBy(0)">arrow_upward</button>
                <button v-else class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 mounth" aria-describedby="protein-status-label" @click="_shortBy(0)">arrow_downward</button>
                <div class="mdc-data-table__header-cell-label" @click="_shortBy(0)" title="Ticker">Ticker</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort" role="columnheader" scope="col" aria-sort="none" data-column-id="m1">
            <div class="mdc-data-table__header-cell-wrapper">
                <div class="mdc-data-table__header-cell-label" style="margin-right:7px" title="Ver en graficador dinámico">Ver en GD</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort" role="columnheader" scope="col" aria-sort="none" data-column-id="m1">
            <div class="mdc-data-table__header-cell-wrapper">
                <div class="mdc-data-table__header-cell-label" title="Operaciones">Operaciones</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
            <th v-for="(item, index) in table_header" :key="index" class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--with-sort" role="columnheader" scope="col" aria-sort="none" data-column-id="m1">
            <div class="mdc-data-table__header-cell-wrapper">
                <button v-if="!is_sorted" class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 mounth" aria-describedby="protein-status-label" @click="_shortBy(index+3)">arrow_upward</button>
                <button v-else class="mdc-icon-button material-icons mdc-data-table__sort-icon-button" aria-label="Sort by 1 mounth" aria-describedby="protein-status-label" @click="_shortBy(index+3)">arrow_downward</button>
                <div class="mdc-data-table__header-cell-label" @click="_shortBy(index+3)" :title="item.name">{{item.sorter}}</div>
                <div class="mdc-data-table__sort-status-label" aria-hidden="true"></div>
            </div>
            </th>
          </tr>
        </thead>
        <tbody class="mdc-data-table__content">
          <tr class="mdc-data-table__row" v-for="(row, index) in combinated_array" :key="index" @click="_changeTicker(index)" :id="'row' + index" :class="{ 'row-default': index % 2 == 0, 'row-grey': index % 2 != 0 }">
            <th v-for="(cel, key) in row" :key='key' scope="row" class="mdc-data-table__cell sector-name">
              <div v-if="key == 0">
                <span :title="'Ticker: ' + cel.ticker 
                  + title_break_line 
                  + 'Pais: ' + cel.country
                  + title_break_line 
                  + 'Índice: ' + cel.index_valor
                  + title_break_line 
                  + 'Divisa: ' + cel.divisa
                  + title_break_line 
                  + 'Sector: ' + cel.sector + ' | ' + cel.industria
                  + title_break_line
                  + 'Tipo de entrada: ' + cel.entry_type_name">{{cel.ticker}}</span>
              </div>
              <div v-else-if="key==1">
                <a :href="'/app/graficador-dinamico/ticker/'+row[0].ticker"><span class="material-icons-outlined">show_chart</span></a>
              </div>
              <div v-else-if="key == 2">
                <span v-if="combinated_array[index][0].waitings_portfolio_name == undefined && !(_g_User.roles[21] || _g_User.roles[20]) " style="cursor: pointer; color: grey;" class="alarm push mdc-list-item__meta material-icons"
                  v-on:click="_getPosition2" @click="_openWaiting(combinated_array[index][0])">more_time</span>
                <span v-if="combinated_array[index][0].waitings_portfolio_name != undefined && !(_g_User.roles[21] || _g_User.roles[20]) " style="color: black; cursor: pointer;" class="alarm push mdc-list-item__meta material-icons"
                  v-on:click="_getPosition2" @click="_openWaiting(combinated_array[index][0])">schedule</span>
                <span v-if="combinated_array[index][0].folder_name.length == 0" style="color: grey; cursor: pointer;" class="favorite mdc-list-item__meta material-icons" 
                  v-on:click="_getPosition" @click="_openFavorites(combinated_array[index][0])">star_border</span>
                <span v-if="combinated_array[index][0].folder_name.length == 1 && workspaces_names.includes(combinated_array[index][0].folder_name[0])" class="favorite mdc-list-item__meta material-icons" 
                  v-on:click="_getPosition" :style="{color: _getColorByWorkspace(combinated_array[index][0].folder_name[0])}" style="cursor: pointer;" @click="_openFavorites(combinated_array[index][0])">star</span>
                <span v-if="combinated_array[index][0].folder_name.length > 1" class="favorite mdc-list-item__meta material-icons" 
                  v-on:click="_getPosition" style="color: black; cursor: pointer;" @click="_openFavorites(combinated_array[index][0])">star</span>
              </div>
              <div v-else>
                <span>{{cel}}</span>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <Workspaces ref="child"
          :position="position"
          :stocks="stocks"
          :stock="selected_ticker"
          :show_workspace="show_workspace"
          @_closeFavoriteWorkspacesWindow="_closeFavoriteWorkspacesWindow">
      </Workspaces>

      <WaitingValues ref="waiting"
          :stocks="stocks"
          :stock="selected_ticker"
          :show_waiting="show_waiting"
          @_closeWaitingValueWindow="_closeWaitingValueWindow"
          @waiting-added="getStocks">
      </WaitingValues>
    </div>
  </div>
</template>



<script>
  import StockTableCustom from "@/components/helpers/StockTable_custom.vue";
  import Workspaces from '@/components/stockchart/components/Workspaces.vue';
  import WaitingValues from '@/components/stockchart/components/WaitingValuesAdd.vue';
  import APICaller from "@/components/codeComponents/APICaller.js";
  import Vue from 'vue';
  import { mapMutations, mapGetters } from "vuex";
  import GetStocks from "@/components/codeComponents/GetStocks.js";

  export default {
    mixins: [APICaller, GetStocks],
    name: 'StockTableCustom',
    components: {
      Workspaces,
      WaitingValues,      
    },
    props: {
      table_header: {type: Array},
      table_body: {type: Array},
      table_obj: {type: Object},
      stocks: {type: Array}
    },
    watch: {
      table_body(newVal, oldVal) {
        if(oldVal != undefined && this.table_body.length != 0 && this.watch_flag){
          this.watch_flag = false
          this._arrayCombinator()
        }
      },
    },
    data() {
      return {
        position: [],
        show_workspace: false,
        selected_ticker: '',

        workspaces_names: [],
        workspaces_colors: [],

        show_waiting: false,
        index: 0,
        combinated_array: [],
        is_sorted: false,
        watch_flag: true,
        title_break_line: "\n"
      };
    },
    computed: {
      ...mapGetters(["_g_order_by"]),
      ...mapGetters(["_g_User"]),
    },
    mounted() {
      const MyComponentConstructor = Vue.extend(StockTableCustom);
      const table = new MyComponentConstructor();

      setTimeout(function(){
        this._startColor();
      }.bind(this), 1000);

      this._initWorkspaces();
      
    },
    methods: {
      iconFavorite(stock) {
        return (stock.favorites == true) ? 'star' : 'star_border';
      },
      _closeFavoriteWorkspacesWindow() {
        this.show_workspace = false;
      },
      _closeWaitingValueWindow(){
        this.show_waiting = false;
      },
      _openWaiting: function(stock){
        this.selected_ticker = stock;
        //this.show_waiting = true; Moved to _sendPositionToWorkspace for smoother transition
      },
      _openFavorites: function(stock){
        this.selected_ticker = stock;
        //this.show_workspace = true; Moved to _sendPositionToWaiting for smoother transition
      },
      _getPosition: function(event){        
        this._sendPositionToWorkspace(event.clientX, event.clientY);
      },
      _getPosition2: function(event){        
        this._sendPositionToWaiting(event.clientX, event.clientY);
      },
      _sendPositionToWorkspace(x, y){
        let sendPositionToWorkspaceInterval = setInterval(() => {
          if (this.$refs.child != undefined) {
            var posicionX = x + 10;	
            var posicionY = y;	
            this.$refs.child._updateWorkspacePosition(posicionX, posicionY);	
            this.show_workspace = true;
            clearInterval(sendPositionToWorkspaceInterval);
          }
        }, 150);
      },
      _sendPositionToWaiting(x,y){
        let sendPositionToWaitingInterval = setInterval(() => {
          if (this.$refs.waiting) {
            var posicionX = x + 30;	
            var posicionY = y;	
            this.$refs.waiting._updateWorkspacePosition(posicionX, posicionY);
            this.show_waiting = true;
            clearInterval(sendPositionToWaitingInterval);
          }
        }, 150);
      },
      _changeTicker: function(index){
        this.setSelectedActiveTicker(this.combinated_array[index][0]);
        this._changeBackground(index)

      },

      _initWorkspaces: function() {
        let success = response => {
          for (let index = 0; index < response.data.folders.length; index++) {
            this.workspaces_names.push(response.data.folders[index][0]);
            this.workspaces_colors.push(response.data.folders[index][1]);
          }
        }

        let url = '/api/v1/stock-finder/favorite-folder/list';
            
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('023', 'Error recuperando la lista de favoritos.');

        this._getAPICall(url, successHandler, failureHandler);

      },

      _getColorByWorkspace(workspace){
        var index = 0;
        if(this.workspaces_names.includes(workspace)){
          index = this.workspaces_names.indexOf(workspace);
        }
        return this.workspaces_colors[index];
      },

      _startColor: function(){
        const element = document.getElementById("row"+this.index);
        if(element != null) {
          //element.style.background="#CAF4FF";
          document.getElementById("row"+this.index).className += " mdc-selected-row";
        }
      },
      _changeBackground: function(value){

        document.getElementById("row"+this.index).classList.remove("mdc-selected-row");
        //document.getElementById("row"+this.index).style.background="";
        //document.getElementById("row"+value).style.background = "#CAF4FF";
        document.getElementById("row"+value).className += " mdc-selected-row";
        this.index = value;
      },
      ...mapMutations(['setSelectedTicker']),
      ...mapMutations(["setOrderBy"]),
      setSelectedActiveTicker(ticker) {
        this.setSelectedTicker(ticker);
      },

      _arrayCombinator: function(){
        this.combinated_array = []
        for(var i = 0; i < this.table_body.length; i++){
          let arrayToAdd = [];
          arrayToAdd.push(this.stocks[i])
          arrayToAdd.push("ver-en-gd")
          arrayToAdd.push("iconos")
          for(var j = 0; j< this.table_body[i].length; j++){
            let elem = this.table_body[i]
            arrayToAdd.push(elem[j])
          }
          this.combinated_array.push(arrayToAdd)
        }
        this._checkWhatPreferenceIsOrdered();
      },
      _shortBy: function(index){
        if(index == 0){
          if(!this.is_sorted){
            this.combinated_array.sort((a,b) => (a[index].ticker > b[index].ticker) ? 1 : -1)
            this.is_sorted = true;
          }else{
            this.combinated_array.sort((a,b) => (a[index].ticker < b[index].ticker) ? 1 : -1)
            this.is_sorted = false;
          }
        }else{
          const is_int = Number.isInteger(this.combinated_array[0][index]);
          const is_float = this._isFloat(this.combinated_array[0][index]);
          const is_number = is_int || is_float;
          let ends_in_euro = false;
          let ends_in_dollar = false;
          let ends_in_percent = false;
          let ends_in_symbol = false;
          if(!is_number) {
            ends_in_euro = this.combinated_array[0][index].substring(this.combinated_array[0][index].length -1) == "€";
            ends_in_dollar = this.combinated_array[0][index].substring(this.combinated_array[0][index].length -1) == "$";
            ends_in_percent = this.combinated_array[0][index].substring(this.combinated_array[0][index].length -1) == "%";
            ends_in_symbol = ends_in_euro || ends_in_dollar || ends_in_percent;
          }
          
          if(!this.is_sorted){
            if(!is_number && ends_in_symbol){
              this.combinated_array.sort((a,b) => (parseFloat(a[index].substring(0, a[index.length -1])) > parseFloat(b[index].substring(0, a[index.length -1]))) ? 1 : -1)
            }else{
              this.combinated_array.sort((a,b) => {
                  if(a[index] == b[index]) {
                      return a[0].ticker > b[0].ticker ? 1 : -1;
                  } else {
                      return a[index] > b[index] ? 1 : -1;
                  }
                }
              )
            }
            this.is_sorted = true;

          }else{
            if(!is_number && ends_in_symbol){
              this.combinated_array.sort((a,b) => (parseFloat(a[index].substring(0, a[index.length -1])) < parseFloat(b[index].substring(0, a[index.length -1]))) ? 1 : -1)
            }else{
                this.combinated_array.sort((a,b) => {
                  if(a[index] == b[index]) {
                      return a[0].ticker > b[0].ticker ? 1 : -1;
                  } else {
                      return a[index] < b[index] ? 1 : -1;
                  }
                }
              )
            }
            this.is_sorted = false;
          }
        }
        let ordertype = "";
        if(this.is_sorted){
          ordertype = "asc"
        }else{
          ordertype = "desc"
        }
        let orderBy = []
        if(index == 0){
          orderBy = [ordertype, "Ticker"]
        }else{
          orderBy = [ordertype, this.table_header[index - 3].sorter]
        }
        
        this.setOrderBy(orderBy)
      },

      _isFloat: function(value){
        const rest = value % 1;
        return isNaN(rest) ? false : rest != 0;
      },

      _checkWhatPreferenceIsOrdered: function(){
        let index = 0
        if(this._g_order_by != undefined){
          let temp = []
          if(this._g_order_by[0] == "asc"){
            this.is_sorted = false;
          }else if(this._g_order_by[0] == "desc"){
            this.is_sorted = true
          }
          for(let i = 0; i < this.table_header.length; i++){
            temp.push(this.table_header[i].sorter)
          }
          index = temp.indexOf(this._g_order_by[1])
 
          if(index == -1){
            this._shortBy(0)  
          }else{
            this._shortBy(index+3)
          }
        }
      }
    }
  }
</script>

<style lang="scss">
@use "@material/checkbox"; 
  @use "@material/icon-button"; 
  @use "@material/data-table/data-table";

  @include checkbox.core-styles;
  @include icon-button.core-styles;
  @include data-table.core-styles;
  @include data-table.theme-baseline;

  $color_dark: #000010;
  $back_white: #FFFFFF;
  $back_light_grey: #F3F4F7;

  .mdc-data-table__header-cell {
    text-transform: uppercase;
    background-color: $back_light_grey;}
  .mdc-data-table__header-cell,
  .mdc-data-table__cell{
    color: $color_dark;
    font-size: 12px;
    letter-spacing: 0px;
    text-align: center;}
  .mdc-data-table__row {
    background-color: $back_white;}
  .index-content {
    display: flex;
    justify-content: center;
    flex-direction: column;}
  p {
    margin: 0;}
  .mdc-data-table {
    margin-bottom: 40px;
    width: 100%;}
  .options {
    cursor: pointer;
  }

  .table-custom{
    height: 87vh;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 97.5%;
  }

  .full-table{
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
  .top-table{
    width: 100%;
  }
  #stock-table-custom{
    span{
      font-size: 13px;
      font-family: "Open sans";
    }
    .material-icons{
      font-size: 20px;
      font-family: 'Material Icons Outlined';
    }
    .material-icons-outlined{
      font-size: 20px;
      font-family: 'Material Icons Outlined';
    }
    .mdc-data-table__cell {
      width: 20px;
      min-width: 20px;
      span{
        font-size: 12px;
      }
      .material-icons {
        font-size: 15px;
      }
      .material-icons-outlined {
        font-size: 15px;
      }
    }
    .mdc-data-table__header-cell {
      width: 20px;
      min-width: 20px;
      padding: 0;
      margin: 0;
      .mdc-data-table__header-cell-label{
        font-size: 10px;
      }
      .mdc-data-table__sort-icon-button{
        font-size: 13px;
        width: 10px;
      }
    }

    .row-grey, .row-default{
      cursor: pointer !important;
    }

    .row-grey{
      background-color: var(--color-custom-table-grey);
    }

    .row-default{
      background-color: var(--color-custom-table-default);
    }

    .row-grey:hover{
      background-color: var(--color-custom-table-hover) !important;
    }

    .row-default:hover{
      background-color: var(--color-custom-table-hover) !important;
    }

    .row-grey *, .row-default * {
      color: var(--color-typography-on-background) !important;
    }
  }

  .mdc-data-table__header-cell {
    background-color: var(--color-custom-table-titles) !important;
    color: var(--color-typography-on-background) !important;
  }

  .mdc-data-table__header-cell * {
    color: var(--color-typography-on-background) !important;
  }

  .mdc-selected-row {
    background-color: var(--color-custom-table-selected) !important;
  }
</style>
