<template>
    <div class="filter-no-permission-container">
        <span class="filter-no-permission-label typo-caption">No tiene permisos.</span>
        <a v-if="link && link != ''" class="filter-no-permission-label-url typo-caption" :href="link"> Adquirir</a>
    </div>
</template>

<script>
    export default {
        name: 'FilterNoPermission',
        props: {
            link: {type: String}
        }
    }
</script>

<style scoped>
    .filter-no-permission-container {
        border: 2px dotted #80808088;
        border-radius: 5px;
        padding: 5px 100px 5px 10px
    }
    .filter-no-permission-label {
        color: black;
    }
    .filter-no-permission-label-url {
        color: #00aedd;
        text-decoration: none;
        cursor: pointer;
    }
</style>