/*
Component: Stock Table
Purpose: 
It is the view of the stocks in table mode, in this view the ds is not seen, only the table with the tickers and their information.
Main functionality:
  -Loads all stocks in table mode
Secondary functionalities:
  
Useful notes:
  
Decission documentation:

*/



<template>
    <div v-if="_g_IsUserLogged" id="stock-table">
        <Table :table_header="table_header" :table_body="table_body" :stocks="stocks" style="margin-left: 6px;"/>

        <Workspaces
            :stocks="stocks"
            :stock="selected_ticker"
            :show_workspace="show_workspace">
        </Workspaces>
    </div>

      <div v-else id="stock-table">
        <TableAnonymous class="blured" :table_header_anonymous="table_header_anonymous" :table_body_anonymous="table_body_anonymous" :stocks_anonymous="stocks_anonymous" style="margin-left: 6px; cursor: not-allowed"/>
        <div style="position:absolute; top: 50%; text-align: center; left: 40%; cursor: not-allowed">
          <p><strong>Si quieres acceder al listado de valores Eurekers inicia sesión.</strong></p>
        </div>
    </div>
</template>



<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import GetStocks from "@/components/codeComponents/GetStocks.js";
import Workspaces from '@/components/stockchart/components/Workspaces.vue'
import Table from '@/components/helpers/StockTable_custom.vue';
import TableAnonymous from '@/components/helpers/StockTable_custom_anonymous.vue';
import { mapMutations, mapGetters } from "vuex";

export default {
  mixins:[APICaller, GetStocks],
  components: {
    Workspaces,
    Table,
    TableAnonymous
  },
  name: 'StocksTable',
  data: () => ({
    open_positions: [],
    preferences_portfolio: [],
    stocks: [],
    selected_ticker: '',
    show_workspace: false,
    order_by: false,
    order_class: false,
    order_type: 'DESC',
    // table
    table_header: [],
    table_body: [],
    preferences: [],

    table_header_anonymous: ["Tipo de entrada", "Entrada", "Stop", "Rentabilidad", "Fecha de entrada"],
    table_body_anonymous: [],
    stocks_anonymous: [],
  }),
  watch: {
    refresh: {
      handler: function(){
        this._getPreferences();
      }
    }, 
    filters: {
      handler: function(){
      },
      deep: true
    },
    _g_stocks: {
      handler: function(newVal, oldVal){
        this.stocks = newVal;
        this._prepareDataTable();
      },
      deep: true
    }
  },
  mounted() {
    var waiting_for_portfolio= setInterval(()=>{
      if(this._g_portfolio_id_selected && this._g_portfolio_id_selected != undefined){
        this._getOpenPositions()
        clearInterval(waiting_for_portfolio)
      }
    }, 20)
  },
  created: function() {
    this.getStocks(this._prepareDataTable);
    this._createAnonymousStock()
    setTimeout(function(){
      this._getPreferences();
    }.bind(this), 1);
  },
  computed: {
    ...mapGetters({refresh: "_g_RefreshTable"}),
    ...mapGetters({stock_type:'_g_GetTypeStocks'}),
    ...mapGetters({filters:'_g_ShowStockFinderFilter'}),
    ...mapGetters({recharge:'_g_RechargeState'}),
    ...mapGetters(["_g_IsUserLogged"]),
    ...mapGetters(["_g_User"]),
    ...mapGetters(["_g_SelectedTicker"]),
    ...mapGetters(["_g_portfolio_id_selected"]),
    ...mapGetters({_g_stocks:'_g_Stocks'}),
  },
  methods: {
    ...mapMutations(['setRechargeState']),
    ...mapMutations(['setStockValue']),
    ...mapMutations(['setMenuSelectionOut']),


    _createAnonymousStock: function(){
      for (var i = 0; i < 36; i++){
        let word = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
        this.stocks_anonymous.push(word.toUpperCase());
      }

      for(var j = 0; j< 36; j++){
        let dataToAdd = [];
        let data = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
        let data2 = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
        let data3 = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
        let data4 = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
        let data5 = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);

        dataToAdd.push(data, data2, data3, data4,data5)

        this.table_body_anonymous.push(dataToAdd)
      }
    },
    _fixDecimalsToTickSize: function(){
      if(this._g_SelectedTicker.tick_size == 0.01){
        return 2;
      }else if(this._g_SelectedTicker.tick_size == 0.1){
        return 1;
      }else{
        return 3;
      }
    },
    _prepareDataTable() {
      this.table_body = []; 
      let that = this;
      if(this.stocks == null) {
        this.stocks = [];
      }
      if(this._g_User.roles[21] || this._g_User.roles[20]){
        this.stocks.forEach(function (ticker) {
          let stockToAdd = [];
          let tickerTipoEntrada = ticker.entry_type_name;
          let tickerAcciones = ticker.instrument_number;
          let tickerEntrada = ticker.real_entry.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerStop = ticker.out_price.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerSoporte = ticker.soport.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerFechaSoporte = ticker.data_soport;
          stockToAdd.push(tickerTipoEntrada, tickerAcciones, tickerEntrada, tickerStop, tickerSoporte, tickerFechaSoporte);

          let tickerMontante = ticker.amount.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerDistanciaEntrada = ticker.entry_distance.toFixed(that._fixDecimalsToTickSize()) + "%";
          let tickerValorDivisa = ticker.cotitzacio_divisa.substring(0,4);
          let tickerResistencia;
          if(ticker.resistance == "-"){
            tickerResistencia = ticker.resistance;
          }else{
            tickerResistencia = parseFloat(ticker.resistance)
            tickerResistencia = tickerResistencia.toFixed(that._fixDecimalsToTickSize())
          }
          that.preferences.forEach(element => {
            if(element.sorter =="Montante"){
              stockToAdd.push(tickerMontante);
            }else if(element.sorter == "Dist. Ent."){
              stockToAdd.push(tickerDistanciaEntrada)
            }else if(element.sorter == "Resist."){
              stockToAdd.push(tickerResistencia)
            }else if(element.sorter == "Divisa"){
              stockToAdd.push(tickerValorDivisa)
            }
          })
          that.table_body.push(stockToAdd);
        })
      }else {
        this.stocks.forEach(function (ticker) {
          let stockToAdd = [];
          let tickerTipoEntrada = ticker.entry_type_name;
          let tickerAcciones = ticker.instrument_number;
          let tickerEntrada = ticker.real_entry.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerStop = ticker.out_price.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerSoporte = ticker.soport.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerFechaSoporte = ticker.data_soport;
          stockToAdd.push(tickerTipoEntrada, tickerAcciones, tickerEntrada, tickerStop, tickerSoporte, tickerFechaSoporte);

          let tickerMontante = ticker.amount.toFixed(that._fixDecimalsToTickSize()) + that._selectDivisa(ticker.divisa);
          let tickerDistanciaEntrada = ticker.entry_distance.toFixed(that._fixDecimalsToTickSize()) + "%";
          let tickerVarAnual = ticker.tae.toFixed(that._fixDecimalsToTickSize())
          tickerVarAnual = parseFloat(tickerVarAnual);
          let tickerNumeroOnda = ticker.num_ona_actual;
          let tickerValorActual = ticker.valor_actual;
          let tickerValorDivisa = ticker.cotitzacio_divisa.substring(0,4);
          let tickerRentabilidad = parseFloat(ticker.tae_lt.toFixed(that._fixDecimalsToTickSize()));
          let tickerResistencia;
          if(ticker.resistance == "-"){
            tickerResistencia = ticker.resistance;
          }else{
            tickerResistencia = parseFloat(ticker.resistance)
            tickerResistencia = tickerResistencia.toFixed(that._fixDecimalsToTickSize())
          }
          let tickerSigLT = ticker.entrada_seg.toFixed(that._fixDecimalsToTickSize());
          let tickerCompatibilidad = parseFloat(ticker.user_platinum_compatibility) * 100;
          tickerCompatibilidad = tickerCompatibilidad.toFixed(2)
          let tickerPrimeraFecha = ticker.fecha_primera_barra;
          let tickerInvRisk = ticker.risk_reward.toFixed(that._fixDecimalsToTickSize());
          let tickerBenefit = that._printPortfolioPreference("benefit", ticker.ticker)
          let tickerOpenFee = that._printPortfolioPreference("open_fee", ticker.ticker)
          let tickerInsuredBenefit = that._printPortfolioPreference("insured_benefit", ticker.ticker)
          let tickerInDate = that._printPortfolioPreference("in_date", ticker.ticker)
          that.preferences.forEach(element => {
            if(element.sorter =="Montante"){
              stockToAdd.push(tickerMontante);
            }else if(element.sorter == "Dist. Ent."){
              stockToAdd.push(tickerDistanciaEntrada)
            }else if(element.sorter == "Resist."){
              stockToAdd.push(tickerResistencia)
            }else if(element.sorter == "Inv/Riesgo"){
              stockToAdd.push(tickerInvRisk)
            }else if(element.sorter == "Valor act."){
              stockToAdd.push(tickerValorActual)
            }else if(element.sorter == "Nº onda"){
              stockToAdd.push(tickerNumeroOnda)
            }else if(element.sorter == "Divisa"){
              stockToAdd.push(tickerValorDivisa)
            }else if(element.sorter == "Rentab."){
              stockToAdd.push(tickerRentabilidad)
            }else if(element.sorter == "Var. Anual"){
              stockToAdd.push(tickerVarAnual)
            }else if(element.sorter == "1ª fecha"){
              stockToAdd.push(tickerPrimeraFecha)
            }else if(element.sorter == "Sig. LT"){
              stockToAdd.push(tickerSigLT)
            }else if(element.sorter == "Compat."){
              stockToAdd.push(tickerCompatibilidad)
            }else if(element.sorter == "Beneficio"){
              stockToAdd.push(tickerBenefit)
            }else if(element.sorter == "Benef. Aseg."){
              stockToAdd.push(tickerInsuredBenefit)
            }else if(element.sorter == "Comisión ent."){
              stockToAdd.push(tickerOpenFee)
            }else if(element.sorter == "F. entrada"){
              stockToAdd.push(tickerInDate)
            }
          })
          that.table_body.push(stockToAdd);
        })
      }
    },
    _selectDivisa : function(valor){
      if (valor == 'EUR'){
        return '€';
      } else{
        return '$';
      }
    },
    //this._prepareDataTable();

    
    ...mapMutations(['setSelectedTicker']),
    setSelectedActiveTicker(ticker) {
        this.setSelectedTicker(ticker);
        this.selected_ticker = ticker;
    },

    _getPreferences: function() {
      this.table_header = [];
      let success = response => {
        this.preferences = response.data;
        this._getPortfolioPreferences()
      }
      
      let url = '/api/v1/stock-finder/preferences'

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('088', 'Error cargando preferencias.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getPortfolioPreferences: function(){
      let success = response =>{
        if(response.data.indexOf("open_fee") != -1){
          this.preferences.push({field: "open_fee", help_text: "", name:"Comisión de entrada", sorter:"Comisión ent.", subfix: "", type: ""})
        }
        if(response.data.indexOf("benefit") != -1){
          this.preferences.push({field: "benefit", help_text: "", name:"Beneficio", sorter:"Beneficio", subfix: "", type: ""})
        }
        if(response.data.indexOf("insured_benefit") != -1){
          this.preferences.push({field: "insured_benefit", help_text: "", name:"Beneficio asegurado", sorter:"Benef. Aseg.", subfix: "", type: ""})
        }
        if(response.data.indexOf("in_date") != -1){
          this.preferences.push({field: "in_date", help_text: "", name:"Fecha de entrada", sorter:"F. entrada", subfix: "", type: ""})
        }
        for(var i = 1; i < this.preferences.length; i++){
          this.table_header.push({name:this.preferences[i].name, sorter:this.preferences[i].sorter })
        }
        this._prepareDataTable();
      }

      let url = "/api/v1/portfolio-preferences"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('089', 'Error cargando preferencias.');

      this._getAPICall(url, successHandler, failureHandler);
    },


    _getOpenPositions() {
      let success = response => {
        this.open_positions = response.data
        this._createArrayToOpenPosition()
      }
       
      let url = '/api/v1/portfolio/open_positions/'+ this._g_portfolio_id_selected;
        
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('090', 'Error cargando posiciones abiertas.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _timeConverter: function(UNIX_timestamp){
      let a = new Date(UNIX_timestamp * 1000);
      let year = a.getFullYear();
      let month = a.getMonth() + 1;
      let date = a.getDate();
      let time = date + '-' + month + '-' + year;
      return time;
    },

    _createArrayToOpenPosition: function(){
      this.open_positions.forEach(element => {
        let objectToAdd = {};
        objectToAdd["ticker"] = element.ticker
        objectToAdd["benefit"] = element.benefit
        objectToAdd["insured_benefit"] = element.insured_benefit
        objectToAdd["in_date"] = this._timeConverter(element.in_date)
        objectToAdd["open_fee"] = element.open_fee
        this.preferences_portfolio.push(objectToAdd)
      })
    },

    _printPortfolioPreference: function(type, ticker){
      let flag = false
      this.preferences_portfolio.forEach(element => {
        if(element.ticker == ticker){
          flag = element[type]
          return true
        }
      })
      if(!isNaN(parseFloat(flag))){
        return flag
      }else{
        return "-"
      }
    },
  },
}
</script>


<style lang="scss" scoped>
@use "@material/list";

@include list.core-styles;
@include list.item-selected-text-color(black);

.mdc-list-item {
  @include list.item-primary-text-ink-color(#444);
  @include list.list-item-height(36px);
  border-bottom: 1px solid #e9e9e9;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple[data-v-1d7efbc2]::before {
    background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, red));
}

li.mdc-list-item {display:flex;}
li span.mdc-list-item__meta.material-icons {margin:unset; margin-left: 4px}
li span.mdc-list-item__meta.material-icons.push {margin-left: auto;}

.mdc-list-item.mdc-list-item--selected::before {
  display:none;
}

li.mdc-list-item--selected .material-icons {
  &.alarm:hover {color:green}
  &.card-travel:hover {color:orange;}
  &.favorite:hover {color:#00add8;}
}

li.mdc-list-item .material-icons {
  &.alarm.active {color:green}
  &.card-travel.active {color:orange;}
  &.favorite.active {color:#00add8;}
}

.ekers-event-click {
  cursor:pointer;
}

.mdc-data-table {
    width: 97.5%;
    margin-left: 0.4%;
    margin-bottom: 1.5%;
    height: 135%;
}

.blured{
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: white;
}

</style>